import request from "./request";

export default class ShowService {
  static getShow(url) {
    return request({
      url: "/shows/show/" + url,
      method: "GET"
    });
  }
  static getShows() {
    return request({
      url: "/shows/all",
      method: "GET"
    });
  }
}
