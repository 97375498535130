import React from 'react';
import css from '../../style/components.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  h2: {
    margin: 0,
    padding: 0,
  },
});

function ProfileItem(props) {

    const classes = useStyles();

  return (
    <Card className={css.ListItem}>

        <Grid container spacing={0}>
          <Grid item xs={3} style={{ padding: 10 }}>
              <Avatar component={Link} to={props.url} alt={props.name} src={'https://cms.starity.com/images/image?url='+props.image} className={css.Avatar} />
          </Grid>
          <Grid item xs={9} style={{ padding: 10 }} component={Link} to={props.url}>
            <strong>{props.name}</strong><br />
            <small>{props.description}</small>
          </Grid>
        </Grid>
    </Card>

  );
}

export default ProfileItem;