import * as ActionTypes from '../ActionTypes';

const initState = {
    searchResults: [],
};

const AppReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.SEARCH_LOADING:
            return {
                ...state,
                //searchResults: "loading...",
            };
        case ActionTypes.SEARCH_SUCCESS:
            return {
                ...state,
                searchResults: action.res,
        };
        //case ActionTypes.LOAD_FOLLOWING_ERROR:
            //return {
               // ...state,
        //};

    default:
    return state;
    }
};
export default AppReducer;