import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles, } from '../Style';
import * as ActionTypes from '../redux/ActionTypes';


function Company() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h4>It was all a dream</h4>
      <h1><span className={css.star}>★</span> Reborn Summer 2023.</h1>
      <p>
        Starity is a long term passion project to create the best personalized entertainment resource.
        From top teir performers to low key gems, Starity allows you to follow what you want, without everything else.
      </p>
      
      <h3>Fun Facts</h3>
      <p>&bull; Britney Spears is the first record in the Starity database</p>
      <p>&bull; The original DNA for Starity can be traced to Artist Link Project (1999) and Digital Access Entertainment (2001)</p>
      <br />
      <br />
    </div>
  );
}

export default Company;