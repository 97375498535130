import {combineReducers} from 'redux';
import AppReducer from './AppReducer';
import AuthReducer from './AuthReducer';
import PageReducer from './PageReducer';
import ProfileReducer from "./ProfileReducer";

const RootReducer = combineReducers({
    appDetails: AppReducer,
    pageDetails: PageReducer,
    userAuth: AuthReducer,
    userDetails: ProfileReducer,
});
export default RootReducer;