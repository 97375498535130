import React from 'react';
import { useState, useReducer, useEffect, useContext } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useQuery } from 'react-query'

import { GetFollowingExperience } from '../services/ProfileService';

import AssetBar from '../components/AssetBar.tsx';

//Layout & Style
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { StyledBadge, useStyles, } from '../Style';

//Images & Icons
import Canvas from "../components/Canvas";
import WidgetSettingsButton from '../components/WidgetSettingsButton';
import * as ActionTypes from '../redux/ActionTypes';

function Following() {

  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  const [ pageSet, setPageSet ] = useState(false);
  const { data: followingData, isLoading, isSuccess } = useQuery(
    "following",
    () => GetFollowingExperience(),
    {
      staleTime: 1 * 60 * 1000, 
      cacheTime: 5 * 60 * 1000
    }
  );
  const followexperience = !isLoading && isSuccess ? followingData : {};
  const token = localStorage.getItem('user-token');
  const isLoggedIn = token !== undefined && token !== null && token !== "";
  const userDetails = useSelector(state=>state?.userDetails?.userProfile);
  // check if user owns the experience
  const isOwner = isLoggedIn && followexperience?.owner_id === userDetails?.id;

  const [isEditable, setIsEditable ] = useState(false);
  const [widgetArray, setWidgetArray] = useState({});
  const [layouts, setLayouts] = useState();

  if (!isSuccess) {
    return "Loading...";
  } else {
    if (pageSet !== "Following") {
      dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Following", assetType: null, assetId: null});
      setPageSet("Following");
      //if (widgetArray !== experience?.widgets) {
        setWidgetArray(followexperience?.widgets);
        setLayouts(followexperience?.grid_layout);
      //}
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8}>
          <AssetBar assets={followexperience?.following} />
        </Grid>
        <Grid item xs={4} md={4} direction={"column-reverse"}>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              pr: 3,
            }}
          >
          { isOwner && <WidgetSettingsButton isEditable={isEditable} setIsEditable={setIsEditable} widgetArray={widgetArray} setWidgetArray={setWidgetArray} />}
          </Box>
        </Grid>
      </Grid>
      <div>
        <Canvas
          key={"following"}
          id={"following"}
          experienceUrl='following'
          isEditable={isEditable}
          isOwner={true}
          widgetArray={widgetArray}
          setWidgetArray={setWidgetArray}
          layouts={layouts}
          setLayouts={setLayouts}
          assets={JSON.stringify(followexperience?.following)}
        />
      </div>
    </div>
);
}

export default Following;

