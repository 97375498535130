import React, { useRef, useState, useEffect } from "react";
import css from '../style/components.module.scss';

// Models
import { People } from '../models/Person';
import { Teams } from '../models/Team';
import { Groups } from '../models/Group';
import { Movies } from '../models/Movie';
import { Shows } from '../models/Show';
import { Games } from '../models/Game';
import { Locations } from '../models/Location';
import { Podcasts } from '../models/Podcast';
import { Assets } from '../models/Asset';

import MicIcon from '@material-ui/icons/Mic';
import TvIcon from '@material-ui/icons/Tv';
import SportsIcon from '@material-ui/icons/Sports';
import GroupIcon from '@material-ui/icons/Group';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import IconButton from '@material-ui/core/IconButton';
import FaceIcon from '@material-ui/icons/Face';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MovieIcon from '@material-ui/icons/Movie';
import Tooltip from '@material-ui/core/Tooltip';

import Badge from '@material-ui/core/Badge';
import { isSet } from "util/types";

const AssetBar = (
    assets: Assets,
    ) => {

    const assetTypes = {
        Person: {
            name: 'Person',
            label: 'People',
            icon: <FaceIcon />,
            color: 'secondary',
        },
        Team: {
            name: 'Team',
            label: 'Teams',
            icon: <SportsIcon />,
            color: 'secondary',
        },
        group: {
            name: 'Group',
            label: 'Groups',
            icon: <GroupIcon />,
            color: 'secondary',
        },
        movie: {
            name: 'Movie',
            label: 'Movies',
            icon: <MovieIcon />,
            color: 'secondary',
        },
        Show: {
            name: 'Show',
            label: 'Shows',
            icon: <TvIcon />,
            color: 'secondary',
        },
        podcast: {
            name: 'Podcast',
            label: 'Podcasts',
            icon: <MicIcon />,
            color: 'secondary',
        },
        game: {
            name: 'Game',
            label: 'Games',
            icon: <SportsEsportsIcon />,
            color: 'secondary',
        },
        location: {
            name: 'Location',
            label: 'Locations',
            icon: <LocationOnIcon />,
            color: 'secondary',
        },
    }

    // check for no assets
    if (assets['assets'] === null) {
        return null;
    }

    //group asset_types from assets
    const assetsByType = assets['assets'].reduce((r: any, a: any) => {
        r[a.type] = [...r[a.type] || [], a];
        return r;
    }, {});

    // People 
    //const shouldPeopleRender: boolean = Object.keys(person ?? []).length > 0;
    const assetTypeRender = (assetType: any) => {
        if (assetsByType[assetType.name] === undefined) {
            return null;
        }
        const assetTooltip = assetsByType[assetType.name].map((asset: any) => {
            // list all assets with comma unless last
            if (asset !== assetsByType[assetType.name][assetsByType[assetType.name].length - 1]) {
                return (<span key={asset.url}>{asset.name}, </span>);
            } else {
                return (<span key={asset.url}>{asset.name}</span>);
            }
        });

        return (
        <Tooltip key={assetType.name} title={assetTooltip} arrow>
            <IconButton aria-label="people" className={css.icon}>
                <Badge badgeContent={assetsByType[assetType.name].length} className={css.badge}>
                    {assetType.icon}
                </Badge>
            </IconButton>
        </Tooltip>
        )
    };

    const renderBar = () => {
        //loop through assetByType and render
        return Object.keys(assetTypes).map((key: any) => {
            return assetTypeRender(assetTypes[key]);
        }
        )
    }

    return (
        <div>
            {assets && renderBar()}
        </div>
    )

}

export default AssetBar;