import request from "./request";

export default class TeamService {
  static getTeam(url) {
    return request({
      url: "/sports/team/" + url,
      method: "GET"
    });
  }
  static getTeams(league_url) {
    return request({
      url: "/",
      method: "GET"
    });
  }
}
