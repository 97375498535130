import React, { useState } from "react";
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import css from '../style/components.module.scss';

import Widget from '../components/widgets/Widget';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { useStyles, } from '../Style';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import TeamService from "../api/TeamService";
import * as ActionTypes from '../redux/ActionTypes';
import { nb } from "date-fns/locale";

export default function Team() {
  const classes = useStyles();
  const theme = useTheme();
  let { url } = useParams();

  const { data: teamData, isLoading, isSuccess } = useQuery(
    ["team-" + url],
    () => TeamService.getTeam(url),
    {
      staleTime: 1 * 60 * 1000, 
      cacheTime: 5 * 60 * 1000  
    }
  );
  const dispatch = useDispatch();
  const [ pageSet, setPageSet ] = useState(false);
  const team = isSuccess ? teamData : {};
  const asset = JSON.stringify([{"type" : "Team", "id": team.id}]);

  const renderPhoto = () => {
    if (team?.photo?.url) {
      return (
          <Avatar style={{ width: '175px', height: '175px', margin: '1em auto', marginTop: '0' }} alt={team.name} src={'https://cms.starity.com/images/image?url='+team.photo.url} />
      );  
    }
  }

    if (!isSuccess) {
      return "Loading...";
    } else {
      if (pageSet !== team?.name) {
        dispatch({type: ActionTypes.SET_PAGE_INFO, pageName: team?.name, assetType: "Team", assetId: team?.id});
        setPageSet(team?.name);
      }
    }

  return (
    <div className={css.page} key={team.url}>
      <div className={classes.subTitle}>
        {isSuccess && team.record && <Chip size="medium" label={team.record} /> } &nbsp;
        {isSuccess && team.standing && <Chip size="medium" label={team.standing} />  }
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Widget experienceUrl={team.url} widgetName="Scoreboard" type="scoreboard" width={4} height={6} assets={asset} widget={{"i": "teamScores-" + team.url, "type": "scoreboard"}} id={'teamScores-' + team.url} />
            <br />
            <Widget experienceUrl={team.url} widgetName="Calendar" type="calendar" width={4} height={6} assets={asset} widget={{"i": "teamCal-" + team.url, "type": "calendar"}} id={'teamCal-' + team.url} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget experienceUrl={team.url} widgetName="Feed" type="feed" assets={asset} widget={{"i": "teamFeed-" + team.url, "type": "feed"}} id={'teamFeed-' + team.url} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            {renderPhoto()}
            <Widget widgetName={isSuccess && team.name + " Roster"} type="list" width={4} height={4} assets={asset}  widget={{"i": "teamRoster-" + team.url, "type": "list"}} id={'teamRoster-' + team.url} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}