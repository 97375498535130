import React from 'react';
import { useState,  useEffect } from 'react';
import { useDispatch } from 'react-redux';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { StyledBadge, theme, drawerWidth, useStyles, } from '../Style';

//Images & Icons
import IconButton from '@material-ui/core/IconButton';
import FaceIcon from '@material-ui/icons/Face';
import Canvas from "../components/Canvas";
import WidgetSettingsButton from '../components/WidgetSettingsButton';

import * as ActionTypes from '../redux/ActionTypes';


const defaultWidgets = [
  { i: "widget1", name: 'Widget One', type: 'feed', x: 0, y: 0, w: 2, h: 2 },
  { i: "widget2", name: 'Widget Two', type: 'scoreboard', x: 2, y: 2, w: 2, h: 2 },
  { i: "widget3", name: 'Widget Three', type: 'calendar', x: 4, y: 4, w: 2, h: 2 },
];

function getFromLS(item,key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(item)) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(item, key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      item,
      JSON.stringify({
        [key]: value
      })
    );
  }
}

function Custom() {

  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  //const originalLayouts = getFromLS("cavnasx-layout", "layouts") || {};
  const originalWidgets = getFromLS("cavnasx-widgets", "widgets") || defaultWidgets;

  const [isEditable, setIsEditable ] = useState(false);
  //const [openModal, setOpenModal] = React.useState(false);
  const [widgetArray, setWidgetArray] = useState(JSON.parse(JSON.stringify(originalWidgets)));

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Custom Page", assetType: null, assetId: null});
    setWidgetArray(JSON.parse(JSON.stringify(originalWidgets)));
  }, []);

    //ScoreBoardModel
    const score1 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Team One", "score":123, "win":true, "image":"/images/team1.png"},
                "team2":{"id":null, "name":"Team Two", "score":111, "win":false, "image":"/images/team2.png"} 
                };
    const score2 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Team Three", "score":23, "win":false, "image":"/images/team3.png"},
                "team2":{"id":null, "name":"Team Four", "score":11, "win":false, "image":"/images/team4.png"} 
                };
    const matches = [score1, score2];


  const handleDelete = (key) => {
    const tempArray = widgetArray.slice();
    const index = tempArray.indexOf(tempArray.find((data) => data.i === key));
    tempArray.splice(index, 1);
    setWidgetArray(tempArray);
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <IconButton aria-label="people">
            <StyledBadge badgeContent={25} color="secondary">
              <FaceIcon />
            </StyledBadge>
          </IconButton>

        </Grid>

        <Grid item xs={12} md={2}>
          <WidgetSettingsButton style={{"marginLeft":"auto"}} isEditable={isEditable} setIsEditable={setIsEditable} widgetArray={widgetArray} setWidgetArray={setWidgetArray} />
        </Grid>
      </Grid>
      <div>
        <Canvas 
          isEditable={isEditable} 
          widgetArray={widgetArray} 
          setWidgetArray={setWidgetArray} 
          saveToLS={saveToLS} 
          getFromLS={getFromLS} 
        />
      </div>
    </div>
);
}

export default Custom;