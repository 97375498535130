import React from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';

//Pages
import Apps from "./pages/Apps";
import Blog from "./pages/Blog";
import Company from "./pages/Company";
import Discovery from './pages/Discovery';
import Home from "./pages/Home";
import Reactions from "./pages/Reactions";
import Team from "./pages/Team";
import Show from "./pages/Show";
import Person from "./pages/Person";
import Location from "./pages/Location";
import Experience from './pages/Experience';
import Following from "./pages/Following";
import Login from "./pages/Login";
import Custom from "./pages/Custom";
import Register from "./pages/Register";
import Membership from "./pages/Membership";
import Sponsor from "./pages/Sponsor";
import Status from "./pages/Status";
import Api from "./pages/Api";
import Support from "./pages/Support";
import Privacy from "./pages/Privacy";
import Profile from "./pages/Profile";
import Terms from "./pages/Terms";
import Beta from "./pages/Beta";
import Four04 from "./pages/404";

import PrivateRoute from './PrivateRoute';
import {Guard} from './Guard';

function Routes(){
    return (
    <>
    <Switch>
        <Route exact path="/" render={props => (
            <Redirect to={{ pathname: '/home' }} />
        )}/>

        <Route path="/reactions">
            <Reactions />
        </Route>
        
        <Route path="/show/:url" children={<Show />} />

        <Route path="/person/:url" children={<Person />} />

        <Route path="/team/:url" children={<Team />} />

        <Route path="/x/:url" children={<Experience />} />

        <Route path="/location">
            <Location />
        </Route>
        <Route path="/following">
            <Following />
        </Route>
        <Route path="/trending">
            <Experience />
        </Route>
        <Route path="/custom">
            <Custom />
        </Route>
        <Route path="/membership">
            <Membership />
        </Route>
        <Route path="/sponsor">
            <Sponsor />
        </Route>
        <Route path="/api">
            <Api />
        </Route>
        <Route path="/status">
            <Status />
        </Route>
        <Route path="/apps">
            <Apps />
        </Route>
        <Route path="/blog">
            <Blog />
        </Route>
        <Route path="/company">
            <Company />
        </Route>
        <Route path="/support">
            <Support />
        </Route>
        <Route path="/privacy">
            <Privacy />
        </Route>
        <Route path="/terms">
            <Terms />
        </Route>
        <Route path="/home">
            <Home />
        </Route>
        <Route path="/discovery">
            <Discovery />
        </Route>


        <Route path="/user/login" component={Login}/>
        <Route path="/user/register" component={Register}/> 
        <Route path="/user" component={Profile}/> 

        <Route path="*">
            <Four04 />
        </Route>

        {/*Redirect if not authenticated */} 
        <Guard path="/user" token="user-token" routeRedirect="/user/login" component={PrivateRoute}/> 

    </Switch>
    </>
    );
}
export default Routes;