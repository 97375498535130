import React, { useState, useRef, useLayoutEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query'

import css from '../../style/components.module.scss';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import ExperienceService from '../../api/ExperienceService';

import Calendar from './Calendar';
import Feed from './Feed';
import ScoreBoard from './ScoreBoard';
import PlayList from './PlayList';
import List from './List';
import { set } from 'date-fns';

function Widget(props) {

  let { url } = useParams();
  const targetRef = useRef();
  const [widgetData, setWidgetData] = useState(props.widgetData ?? {});
  const [dimensions, setDimensions] = useState({ width:680, height: 420 });

    // holds the timer for setTimeout and clearInterval
    let movement_timer = null;

    // the number of ms the window size must stay the same size before the
    // dimension state variable is reset
    const RESET_TIMEOUT = 100;

    const test_dimensions = () => {
      // For some reason targetRef.current.getBoundingClientRect was not available
      // I found this worked for me, but unfortunately I can't find the
      // documentation to explain this experience
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    }

    const { data: widgetBlockData, isLoading, isSuccess } = useQuery(
      "canvas-" + props.widget.i + props.experienceUrl,
      () => ExperienceService.getCanvas(url, props.assets, JSON.stringify([props.widget])),
      {
        staleTime: 2 * 60 * 1000, 
        cacheTime: 2 * 60 * 1000
      }
    );

    const blockData = isSuccess ? widgetBlockData['data'] : {};

    // This sets the dimensions on the first render
    useLayoutEffect(() => {
      test_dimensions();
      
      return () => {
      }

    }, []);

    // every time the window is resized, the timer is cleared and set again
    // the net effect is the component will only reset after the window size
    // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
    // redrawing of the component for more complex components such as charts
    window.addEventListener('resize', ()=>{
      clearInterval(movement_timer);
      movement_timer = setTimeout(test_dimensions, RESET_TIMEOUT);
    });

    useLayoutEffect(() => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight
        });
      }
    }, []);


  // Dynamically load the proper widget based on [props.type]
  function renderWidget() {

    if (!isSuccess || isLoading || blockData[props.id] === undefined || blockData[props.id] === null || blockData[props.id] == {}) {
      return (<Skeleton variant="rect" animation="wave" height={'90%'} />);
    }

    //check if data === {}
    if (Object.keys(blockData[props.id]).length === 0) {
      return ('No Data');
    }

    if (props.type == "calendar") {
      return <Calendar key={props.id + props.experienceUrl} className={css.Calendar} calendarData={blockData[props.id]} id={props.id} dimensions={dimensions} width={props.width} height={props.height} />
    } else if (props.type == "feed") {
      return <Feed className={css.Feed} feedData={blockData[props.id]} id={props.id} dimensions={dimensions} width={props.width} height={props.height} />
    } else if (props.type == "scoreboard") {
      return <ScoreBoard className={css.ScoreBoard} scoreboardData={blockData[props.id]} id={props.id} dimensions={dimensions} width={props.width} height={props.height} />
    } else if (props.type == "playlist") {
      return <PlayList key={props.id + props.experienceUrl} className={css.PlayList} dimensions={dimensions} showPlaylist={props.showPlaylist} playlistData={blockData[props.id]} id={props.id} width={props.width} height={props.height} />
    } else if (props.type == "list") {
      return <List className={css.List} listData={blockData[props.id]} dimensions={dimensions} id={props.id} width={props.width} height={props.height} />  
    } else {
      return props.children
    }
  }
  
  return (
    <div key={'widget' + props.id + props.experienceUrl} className={css.Widget}>
      <header className={css.WidgetHeader}>
        <Grid container spacing={3} alignContent="center" alignItems="flex-end">
          <Grid item xs={10}>
            <h4>{props.widgetName}</h4>
          </Grid>
        </Grid>
      </header>
      <div className={css.WidgetContent} ref={targetRef}>
        {widgetData && renderWidget()}
      </div>
    </div>
  );
}

export default Widget;
