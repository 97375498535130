import React from "react";
import { useState, useRef, useLayoutEffect } from 'react';
import { useParams, Link } from "react-router-dom";
import { useQuery } from 'react-query'

import { useStyles, } from '../Style';
import css from '../style/components.module.scss';
import { Responsive, WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector} from 'react-redux';
import { UpdateExperienceAction } from "../redux/actions/ProfileActions";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import ExperienceService from '../api/ExperienceService';

import Widget from './widgets/Widget';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@material-ui/icons/Settings';

//modal
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { on } from "events";
import { set } from "date-fns";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Switch from '@mui/material/Switch';
import { render } from "@testing-library/react";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function getModalStyle() {
  const top = 45;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: '1em',
  };
}

const Canvas = (props) => {

  const classes = useStyles();
  const dispatcher = useDispatch();

  const [blocksArray, setBlocksArray] = useState(props.widgetArray);

  const assetObject = (type, id) => {
    return {
      type: type,
      id: id,
    }
  }

  //modal
  const [activeBlockKey, setActiveBlockKey] = React.useState(0);
  
  const [activeBlockAssets, setActiveBlockAssets] = React.useState(blocksArray[activeBlockKey]?.assets ?? []);
  const [activeBlockName, setActiveBlockName] = React.useState(blocksArray[activeBlockKey ?? 0]?.name ?? "Block Name");
  const [activeBlockOptions, setActiveBlockOptions] = React.useState(blocksArray[activeBlockKey ?? 0]?.options ?? {});

  const [openBlockModal, setOpenBlockModal] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [tabValue, setTabValue] = React.useState('1');

  const handleTabChange = (event, newtabValue) => {
    setTabValue(newtabValue);
  };

  //set blocksArray.assets if it is undefined
  if (activeBlockAssets === undefined || activeBlockAssets == []) {
    //add all props.assets to blocksArray[activeBlockKey]?.assets
    const tempArray = JSON.parse(props.assets).map((asset) => assetObject(asset.type, asset.id));
    setActiveBlockAssets(tempArray);
  }

  const onLayoutChange = (layout, layouts) => {
    props.setLayouts(layouts);
    const tempArray = props.widgetArray;
    layout?.map((position) => {
      tempArray[Number(position.i)].x = position.x;
      tempArray[Number(position.i)].y = position.y;
      tempArray[Number(position.i)].w = position.w;
      tempArray[Number(position.i)].h = position.h;
    });
    props.setWidgetArray(tempArray);

    if (props.isEditable && props.isOwner) {
      dispatcher(UpdateExperienceAction(props.experienceUrl, layouts, props.widgetArray, null));
    }
  }

  const handleDelete = (key) => {
    const tempArray = props.widgetArray;
    const index = tempArray.findIndex((item) => item.i === key);
    tempArray.splice(index, 1);
    props.setWidgetArray(tempArray);

    if (props.isEditable && props.isOwner) {
      dispatcher(UpdateExperienceAction(props.experienceUrl, props.layouts, props.widgetArray, null));
    }
  };

  // Opens block options modal
  const handleBlockOptions = (key) => {
    setActiveBlockKey(key);
    setActiveBlockAssets(blocksArray[key]?.assets);
    setActiveBlockName(blocksArray[key]?.name);
    setActiveBlockOptions(blocksArray[key]?.options);
    setOpenBlockModal(true);
  };

  const handleOptionsUpdate = (event) => {
    setActiveBlockOptions({
      ...activeBlockOptions,
      [event.target.name]: event.target.checked,
    });
    console.log(activeBlockOptions);
  }

  const handleCloseModal = () => {
    setOpenBlockModal(false);
  };

  const handleSaveModal = () => {
    const tempArray = props.widgetArray;
    tempArray[activeBlockKey].name = activeBlockName;
    tempArray[activeBlockKey].assets = activeBlockAssets;
    tempArray[activeBlockKey].options = activeBlockOptions;
    props.setWidgetArray(tempArray);
    setOpenBlockModal(false);
    dispatcher(UpdateExperienceAction(props.experienceUrl, props.layouts, props.widgetArray, null));
    setBlocksArray(props.widgetArray);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: "-.25em",
  }));

  const renderBlockSettingOptions = () => {
    if (blocksArray[activeBlockKey].type.toLowerCase() === "feed") {
      return (
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Include in Feed:</FormLabel>
          <FormGroup>
            <FormControlLabel
              key={'option1'}
              control={
                <Switch checked={(activeBlockOptions !== null && activeBlockOptions !== undefined && activeBlockOptions.showNews === false) ? false : true } onChange={handleOptionsUpdate} name="showNews" />
              }
              label="News"
            />
            <FormControlLabel
              key={'option2'}
              control={
                <Switch checked={(activeBlockOptions !== null && activeBlockOptions !== undefined && activeBlockOptions.showSocial === false) ? false : true } onChange={handleOptionsUpdate} name="showSocial" />
              }
              label="Social Posts"
            />
          </FormGroup>
        </FormControl>
        )
    } else {
      return (<></>)
    }
  }

  const renderBlockSettings = (key) => {
    //let blockInfo = blocksArray[activeBlockKey];
    //let blockAssets = blocksArray[activeBlockKey]?.assets ?? activeBlockAssets;
    const assets = JSON.parse(props.assets);

    return (
      <Modal
      className={css.modal}
      open={openBlockModal}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.modalPaper}>
        <div className={css.ModalContent} style={{'minHeight': '35em'}}>
          <h2>Edit {blocksArray[activeBlockKey]?.type.charAt(0).toUpperCase()+ blocksArray[activeBlockKey]?.type.slice(1)} Block</h2>
          
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Block Settings" value="1" />
                <Tab label="Block Sources" value="2" />
              </TabList>
            </Box>
            
            
            <TabPanel value="1">
              <FormControl fullWidth sx={{ m: 1 }} >
                <TextField id="name" label="Name/Heading" variant="outlined" value={activeBlockName} onChange={(e) => setActiveBlockName(e.target.value)} />
              </FormControl>

              <br /><br />

              {renderBlockSettingOptions()}

            </TabPanel>
                        
            <TabPanel value="2">
              <div style={{margin: "1em"}}>
                Sources: <span>Select All</span> | <span>Clear All</span>
              </div>

              <FormControl fullWidth style={{margin: "1em", maxHeight: "17em", overflow: "scroll"}} >
              {assets?.map((asset, index) => (
                  <Item key={index} style={{textAlign:'left'}}>
                    <input type="checkbox"
                      //determine if this source is in activeBlockAssets
                      onChange={(e) => {
                        console.log("e.target.checked", e.target.checked);
                        if (e.target.checked) {
                          const tempArray = [...activeBlockAssets, assetObject(asset.type, asset.id)]
                          setActiveBlockAssets(tempArray);
                        } else {
                          const tempArray = [...activeBlockAssets.filter((item) => item.type !== asset.type || item.id !== asset.id)]
                          setActiveBlockAssets(tempArray)
                        }
                      }}
                      checked={activeBlockAssets?.some((item) => item.type === asset.type && item.id === asset.id)}
                    /> 
                    {asset.name}
                  </Item>
                ))}
              </FormControl>
            </TabPanel>
          </TabContext>

        </div>
        <Grid container className={css.ModalFooter} spacing={3} justifyContent="flex-end">
          <Grid item xs={12} md={8}>
            <Button variant="outlined" color="primary" onClick={handleCloseModal}>Cancel</Button> &nbsp;
            <Button variant="contained" color="primary" onClick={handleSaveModal}>Save</Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
    ); 
  };

  const widgetRender = blocksArray?.map((widget, index) => {
    const id = widget?.i;
    return (
      <div
        className="0"
        key={index}
        data-grid={{
          x: widget?.x,
          y: widget?.y,
          w: widget?.w,
          h: widget?.h,
          i: widget?.i,
          minW: 2,
          maxW: 8,
          minH: 2,
          maxH: 12,
          isBounded: true,
          assets: widget?.assets ?? [],
        }}
      >
        <ButtonGroup variant="contained" aria-label="outlined primary button group" className={css.editWidgetButtons}>
          {props.isEditable && <IconButton aria-label="delete" size="small" onClick={() => handleBlockOptions(index)}><SettingsIcon fontSize="inherit" /></IconButton>}
          {props.isEditable && <IconButton aria-label="delete" size="small" onClick={() => handleDelete(widget.i)}><DeleteForeverIcon fontSize="inherit" /></IconButton>}
        </ButtonGroup>
        <Widget key={widget?.i + props.experienceUrl} experienceUrl={props.experienceUrl} widgetName={widget?.name} type={widget?.type} width={widget?.w} height={widget?.h} id={widget?.i} widget={widget} assets={props.assets} />
      </div>
    );
  });

    return (
      <div>
        <ResponsiveReactGridLayout
          key={props.experienceUrl + 'canvas'}
          //verticalCompact={true}
          onLayoutChange={(layout, layouts) =>
            onLayoutChange(layout, layouts)
          }
          className={css.layout}
          isDraggable={props.isEditable ?? false}
          isResizable={props.isEditable ?? false}    
          layouts={props.layouts}
          breakpoints={{ lg: 1000, md: 700, sm: 500, }}
          preventCollision={false}
          cols={{ lg: 12, md: 8, sm: 4 }}
          autoSize={true}
        > 
          {widgetRender}
        </ResponsiveReactGridLayout>
        {props.isEditable && renderBlockSettings(activeBlockKey)}
      </div>
    );
};

export default Canvas;