import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../Style';

import css from '../style/components.module.scss';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AddIcon from '@mui/icons-material/Add';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import { FollowingAction, PinAction } from '../redux/actions/ProfileActions';
import * as ActionTypes from '../redux/ActionTypes';

// Modal
import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

function PageHeader(props) {

  const dispatcher = useDispatch();
  const classes = useStyles();
  const pageDetails = useSelector(state=>state.pageDetails);
  const [modalStyle] = React.useState(getModalStyle);
  const [openModal, setOpenModal] = React.useState(false);
  const userExperiences = useSelector(state=>state?.userDetails?.userExperiences);

  const [selectedExperiences, setSelectedExperiences] = useState([]);


  const isStarred = props.isStarred;
  const isPinned = props.isPinned;

  function getModalStyle() {
    const top = 35;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      borderRadius: '1em',
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
      setOpenModal(false);
  };
  const toggleFollow = () => {
    dispatcher(FollowingAction(pageDetails.assetType, pageDetails.assetId));
  }
  const togglePin = () => {
    dispatcher(PinAction(pageDetails.assetType, pageDetails.assetId));
  }


  return (
    <>
    <Grid container spacing={3} className={css.PageHeader} alignItems="center">
      <Grid item xs={12} md={9}>
        <h1>{pageDetails.assetType != 'home' ? pageDetails.pageName : null}</h1>
      </Grid>
      <Box item sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} md={3} alignItems="center">
        {pageDetails.assetId !== null && <Tooltip title={ isPinned ? "Pinned" : "Pin to Navigation" } arrow><IconButton size="small" color="primary" aria-label="Pin" component="span" onClick={togglePin}>{ !isPinned && <BookmarkBorderIcon /> }{ isPinned && <BookmarkIcon /> }</IconButton></Tooltip>} &nbsp;
        {pageDetails.assetId !== null && <Tooltip title={ isStarred ? "Following" : "Follow" } arrow><IconButton size="small" color="primary" aria-label="Pin" component="span" onClick={toggleFollow}>{ !isStarred && <StarBorderIcon /> }{ isStarred && <StarIcon /> }</IconButton></Tooltip>} &nbsp;
        {pageDetails.assetId !== null && <Tooltip title="Add to Experience" arrow><IconButton size="small" color="primary" aria-label="Pin" component="span" onClick={handleOpenModal}><AddIcon /></IconButton></Tooltip>}
      </Box>
    </Grid>

    <Modal
          className={css.modal}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.modalPaper}>
            <div className={css.ModalContent}>
              <h2>Add or Remove from Experiences</h2>
              {userExperiences?.map((experience, index) => (
                <Item key={index} style={{textAlign:'left'}}>
                  <input type="checkbox"
                    checked={selectedExperiences.includes(experience.url)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedExperiences([...selectedExperiences, experience.url]);
                      } else {
                        setSelectedExperiences(selectedExperiences.filter(exp => exp !== experience.url));
                      }
                    }}
                  /> 
                  {experience.name}
                </Item>
              ))}
            </div>
            <Grid className={css.ModalFooter} container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} md={6} direction="column" alignItems="flex-start" justifyContent="flex-end">
                <Button variant="contained" onClick={handleCloseModal}>Update</Button>
              </Grid>
            </Grid>
          </div>
    </Modal>
    </>
  );
}

export default PageHeader;