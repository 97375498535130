import React from 'react';
import {Switch,Route,Redirect} from 'react-router-dom';
import Profile from './pages/Profile';

export default function PrivateRoute(props) {
    return (
        <div>
            {/*<Header/>*/}
            <Switch>
                <Route exact path={`${props.match.path}/view-profile`} component={Profile}/>
                <Route exact path={props.match.path} render={props=> (
                    <Redirect to={{ pathname: `${props.match.path}/view-profile` }} />
                )} />

            </Switch>
        </div>
    );
}