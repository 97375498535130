import HttpService from './HttpService';
import axios from 'axios'

export const Search = async (query) => {
    const http = new HttpService();
    let apiUrl = "search?search=" + query;
    const tokenId = "user-token";
    try {
        const data = await http.getData(apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}