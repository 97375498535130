import React,{useState, useEffect} from "react";
import css from '../style/components.module.scss';

import { useHistory } from 'react-router-dom';
import { Button, TextField } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useStyles } from "../Style";
import {useDispatch, useSelector} from 'react-redux';
import {RegisterAction} from '../redux/actions/AuthActions';

import PendingIcon from '@mui/icons-material/Pending';
import Alert from '@material-ui/lab/Alert';
import * as ActionTypes from '../redux/ActionTypes';
import { set } from "date-fns";

function Register() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const authResponse = useSelector(state=>state.userAuth.authResponse);
    const dispatcher = useDispatch();

    const [fields, setState] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    });

    useEffect(() => {
        dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Create Account", assetType: null, assetId: null});
    }, []);

    const handleFieldChange = e => {
        setState({
        ...fields,
        [e.target.id] : e.target.value
    })
    }
    const UserRegister = (e) => {
        e.preventDefault();
        const passwordMatch = checkPasswordMatch(fields.password, fields.password_confirmation);
        if(passwordMatch === true){
            alert('passwords dont match. please check your password again');
            return;
        }
        dispatch(RegisterAction(fields));

        //if (authResponse.success == true) {
            history.push("/user/login");
        //}

    };

    console.log(authResponse);
    
    const checkPasswordMatch = (password,password_confirmation) => {
        return password !== password_confirmation ? true : false; 
    }

    return (
    <div className={css.home}>

        <Alert icon={<PendingIcon fontSize="inherit" />} severity="error" style={{"background": "#0B0014", "color": "white"}}>
            BETA Release: Some features and information are still a work in progress. Thank you for your patience.
        </Alert>

        <form onSubmit={UserRegister}>
        <div>
            <TextField
            type="text"
            className={classes.fullWidth}
            required
            margin="normal"
            variant="outlined"
            label="name"
            id="name"
            value={fields.name}
            onChange={handleFieldChange}
            />
        </div>
            <div>
                <TextField
                type="email"
                className={classes.fullWidth}
                required
                margin="normal"
                variant="outlined"
                label="email"
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
                />
            </div>
        <div>
            <div>
                <TextField
                className={classes.fullWidth}
                label="Password"
                type="password"
                margin="normal"
                variant="outlined"
                required
                id="password"
                value={fields.password}
                onChange={handleFieldChange}
                />
                </div>
                <div>
                <TextField
                className={classes.fullWidth}
                label="Confirm Password"
                type="password"
                required
                margin="normal"
                variant="outlined"
                id="password_confirmation"
                value={fields.password_confirmation}
                onChange={handleFieldChange}
                />
            </div>
            <div>
                <Button
                    type="submit"
                    className={classes.fullWidth}
                    variant="contained"
                    color="primary"
                    endIcon={<AccountCircleIcon />}
                    style={{"margin": "1em 0"}}
                >
                <b>Register</b>
                </Button>
            </div>
        </div>
        </form>
    </div>
    );
}
export default Register;