import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';

export default function ExperienceBox(props) {
  return (
    <Card sx={{ maxWidth: 345 }}  >
      <CardActionArea component={Link} to={props.url}>
        {props.image && <CardMedia
        sx={{ height: 200, backgroundPosition: 'top' }}
        image={props.image}
          alt=""
        />}
        <CardContent
                sx={{ height: 150 }}
        >
          <Typography gutterBottom variant="h6" component="h3" style={{fontSize: '1.25em'}}>
            {props.title}
          </Typography>
          <Typography gutterBottom variant="h4" component="h5" style={{fontSize: '1em', marginTop: '1em'}}>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}