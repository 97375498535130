import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import css from '../../style/components.module.scss';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { GridScroller } from '../GridScroller.tsx';
import { render } from '@testing-library/react';

//calendarData is a JSON object with the following format:
// {"April 6, 2023":{"items":[{"title":"Calendar Item","description":"Calendar Item Description","image":"https://via.placeholder.com/300x300"},{"title":"Calendar Item2 ","description":"Calendar Item Description22","image":"https://via.placeholder.com/300x300"},{"title":"Calendar Item 33","description":"Calendar Item Description33","image":"https://via.placeholder.com/300x300"}]},"April 7th, 2023":{"items":[{"title":"Calendar Item","description":"Calendar Item Description","image":"https://via.placeholder.com/300x300"},{"title":"Calendar Item2 ","description":"Calendar Item Description22","image":"https://via.placeholder.com/300x300"},{"title":"Calendar Item 33","description":"Calendar Item Description33","image":"https://via.placeholder.com/300x300"}]}}

function Calendar({calendarData, id, width, height, ...props}) {

  let activeDate;
  const [calData, setCalData] = useState(calendarData ?? []);

  useEffect(() => {
    // This function runs when the component mounts
    setCalData(calendarData ?? {});

    return () => {
      // This function runs when the component unmounts or when playlistData changes
      setCalData({});
    };
  }, [calendarData]); // The cleanup function runs when any of these dependencies change


  const itemsPerView = () => {
    if (height == 2) { return 3; }
    if (height == 3) { return 6; }
    if (height == 4) { return 8; }
    if (height == 5) { return 12; }
    return height * 2;
  };

  const renderCalendarDate = (date) => {
    if (date != activeDate) {
      activeDate = date;
      return (
        <Grid item xs={2} spacing={0} className={css.CalendarDate}>
          <h2>{calendarData[date]['month'].toUpperCase()}</h2>
          <h3>{calendarData[date]['day']}</h3>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={2}>
          &nbsp;
        </Grid> 
      );
    }
  };

  const renderItems = () => {
    let returnItems = [];

    Object.keys(calendarData)?.map((date) => (
          //prevent calendarData[date]['items'].map from error if calendarData[date]['items'] is not an array
            Array.isArray(calendarData[date]['items']) &&
            calendarData[date]['items'].map((item) => {
            returnItems.push(
              <Grid container spacing={0} key={item.description}>
                {renderCalendarDate(date)}
                <Grid item xs={10} spacing={0}>
                  <Card className={css.ListItem} component={Link} to={item.url}>
                    <Grid item xs={12} style={{ margin: '1em' }}>
                      <strong>{item.description}</strong><br />
                      <small>{item.title}</small>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            );
          })
    ));
    return returnItems;
  }

  return (
    <div className={css.Calendar}>
      <GridScroller itemsPerView={itemsPerView()}>
        {
          calendarData && renderItems()
        }
      </GridScroller>
    </div>
  );

}

export default Calendar;
