import * as ActionTypes from '../ActionTypes';

const initState = {
    pageName: "",
    assetType: "",
    assetId: "",
};

const PageReducer = (state = initState, action) => {

    switch (action.type) {

        case ActionTypes.SET_PAGE_INFO:
        return {
            ...state,
            pageName: action.pageName,
            assetType: action.assetType,
            assetId: action.assetId,
        };

        default:
        return {...state};
    }
};
export default PageReducer;