import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import css from '../../style/components.module.scss';
import { useStyles } from '../../Style';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import QueueIcon from '@mui/icons-material/Queue';

//Playlist
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { render } from '@testing-library/react';

import { Scroller } from '../Scroller.tsx';

import * as ActionTypes from '../../redux/ActionTypes';
import { AddUserMediaAction, RemoveUserMediaAction } from '../../redux/actions/ProfileActions';
import { set } from 'date-fns';

function PlayList({playlistData, playListoptions, width, height, id, dimensions}) {
  const classes = useStyles();
  const styles = {
    root: {
      backgroundPosition: 'top',
    },
  };

  useEffect(() => {
    // This function runs when the component mounts
    setPlaylist(playlistData ?? {});

    return () => {
      // This function runs when the component unmounts or when playlistData changes
      setPlaylist({});
    };
  }, [playlistData]); // The cleanup function runs when any of these dependencies change

  const targetRef = useRef();
  const userLoggedIn = useSelector(state=>state.userDetails.userLoggedIn);
  let userMedia = useSelector(state=>state.userDetails.userMedia);

  const [viewMode, setViewMode] = React.useState('collection');
  const [playlist, setPlaylist] = React.useState(playlistData ?? {});
  const [activeCollection, setActiveCollection] = React.useState(playlist[Object.keys(playlist)[0]] ?? {});
  const [activeSeason, setActiveSeason] = React.useState(activeCollection ? activeCollection['items'][Object.keys(activeCollection['items'])[0]] : {});

  const [activePlaylist, setActivePlaylist] = React.useState(activeSeason ? activeSeason['items'] : {});
  const [activeTrack, setActiveTrack] = React.useState(0);

  const theme = useTheme();
  const dispatcher = useDispatch();

  const resetPlaylist = () => {
    setPlaylist({});
    setActiveCollection({});
    setActiveSeason({});
    setActivePlaylist({});
    setActiveTrack(0);
  };

  const handleCollectionChange = (collection) => {
    setActiveCollection(playlist[collection]);
    setActiveSeason(playlist[collection]['items'][Object.keys(playlist[collection]['items'])[0]]);
    setActivePlaylist(playlist[collection]['items'][Object.keys(playlist[collection]['items'])[0]]['items']);
    setActiveTrack(0);
    setViewMode('playlist');
  };

  const handleSeasonChange = (season) => {
    setActiveSeason(activeCollection['items'][season]);
    setActivePlaylist(activeCollection['items'][season]['items']);
    setActiveTrack(0);
  };

  const handleNext = () => {
    if (activeTrack < activePlaylist.length - 1) {
      setActiveTrack(activeTrack + 1);
    } else {
      setActiveTrack(0);
    }
  };

  const handleBack = () => {
    if (activeTrack > 0) {
      setActiveTrack(activeTrack - 1);
    } else {
      setActiveTrack(activePlaylist.length - 1);
    }
  };

  const handleUserMedia = (action) => {
    if (action === 'remove') {
      dispatcher(RemoveUserMediaAction("Show", activeCollection.id, '{"'+activePlaylist[activeTrack].season+'": ['+activePlaylist[activeTrack].number+']}'));
    } else {
      dispatcher(AddUserMediaAction("Show", activeCollection.id, '{"'+activePlaylist[activeTrack].season+'": ['+activePlaylist[activeTrack].number+']}'));
    }
    handleNext();
  }

  const getWatched = (collection) => {
    let x = 0; // Initialize x to 0
    let y = 0; // Initialize y to 0
    const collectionID = playlist[collection]['id'];
    
    Object.keys(playlist[collection]['items']).forEach((season) => {
      playlist[collection]['items'][season]['items'].forEach((track) => {
        x++; // Increment x for each track
  
        if (
          userMedia.hasOwnProperty("Show:" + collectionID) &&
          userMedia["Show:" + collectionID].hasOwnProperty(track.season) &&
          userMedia["Show:" + collectionID][track.season] !== null &&
          Array.isArray(userMedia["Show:" + collectionID][track.season]) &&
          userMedia["Show:" + collectionID][track.season].includes(track.number)
        ) {
          y++; // Increment y for each watched track
        }
      });
    });
  
    return Math.round((y / x) * 100);
  };

  const handlePlay = () => {
    //todo
  };

  if (playlistData == undefined) {
    return (
      <div>Error.</div>
    )
  }

  function renderSeasonList() {
    return (
      <Grid container style={{marginBottom: '1em'}}>
        <Grid item xs={12}>
          <select
            onChange={(e) => handleSeasonChange(e.target.value)}
            style={{'marginTop': '1em', 'padding': '0.25em', 'border': '1px solid #ccc','width': '95%', 'maxWidth': '30em'}}
          >
            {Object.keys(activeCollection['items']).map((season, index) => (
              <option key={season} value={season}>{season}</option>
            ))}
          </select>
        </Grid>
      </Grid> 
      );
  }
  
  function renderItemList() {
    return (
        <ul
          className={css.PlaylistItemList}
          style={{'height': height * 7 + 'em'}}
        >
        {Object.keys(activeCollection.items).map((season, index) => (
            activeCollection['items'][season]['items'].map((track, index) => {
              let trackName = track.title;
              let showMedia = (userMedia.hasOwnProperty("Show:"+activeCollection.id) && 
                userMedia["Show:"+activeCollection.id].hasOwnProperty(track.season) && 
                userMedia["Show:"+activeCollection.id][track.season] != null &&
                Array.isArray(userMedia["Show:"+activeCollection.id][track.season]) &&
                userMedia["Show:"+activeCollection.id][track.season]?.includes(track.number)) ?
                <RadioButtonCheckedIcon style={{'height': '.5em', 'width': '.5em'}} /> :
                <span style={{'display': 'inline-block', 'height': '.5em', 'width': '1em'}}></span>;

              return (<li key={track.season + index}>{showMedia} {(activePlaylist[0].season == track.season && activeTrack == index) ? <strong>{trackName}</strong> : trackName}</li>)
            })
        ))}
        </ul>
    );
  }

  function getItemsPerView() {
    if (dimensions.width == 0) {
      return 2;
    }
    return Math.floor(dimensions.width / 120);
  }


  function renderCollectionTag(collection) {
    let tag;

    // if collection date is within last 7 days, show "New" tag
    const date = new Date(playlist[collection]['date']);
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // if all items are watched, show "Watched" tag
    let isWatched = false;
    if (userMedia.hasOwnProperty("Show:"+playlist[collection]['id'])) {
      const watched = getWatched(collection);
      if (watched === 100) {
        isWatched = "Watched!";
      } else {
        isWatched = watched+'% Watched';
      }
    }

    if (isWatched === "Watched!") {
      tag = isWatched;
    } else if (diffDays <= 7) {
      tag = "New Episode";
    } else if (isWatched != false) {
        tag = isWatched;
    } else {
      // show number of seasons
      let seasons = Object.keys(playlist[collection]['items']).length;
      let seasonLabel = seasons > 1 ? "Seasons" : "Season";
      tag = seasons + " " + seasonLabel;
    }

    return (
      <h2 className={classes.collectionTag}>{tag}</h2>
    );
  }

  // show the image for each collection with link to handle collection change
  function renderCollections() {
    return (
          <Scroller itemsPerView={getItemsPerView()} key={id}>
            {Object.keys(playlist).map((collection, index) => (
                <div style={{'padding': '0', 'marginTop': '1em'}} key={collection.name + id}>
                  <CardMedia
                    onClick={(e) => handleCollectionChange(collection)}
                    className={classes.cover}
                    image={'https://cms.starity.com/images/image?url='+playlist[collection]['image']}
                    title=""
                    padding="0"
                    style={{'width':'10em', 'margin':'0 auto', 'cursor':'pointer'}}
                  />
                  {renderCollectionTag(collection)}
                </div>
            ))}
          </Scroller>
    );
  }

  const renderBackButton = () => {
    return (
      <Grid item xs={12} className={classes.contentplaylist}>
        <IconButton aria-label="previous" onClick={() => setViewMode('collection')}>
          <ArrowBackIcon />
        </IconButton>
      </Grid>
    );
  };

  function renderPlaylist() {

    return (
      <Grid container>    
      {playlist.length > 1 
        ? <Grid item xs={(width > 4) ? 1 : 2}>{renderBackButton()}</Grid> 
        : <span style={{'width': '1em'}}>&nbsp;</span>
      }
      <Grid item xs={(width > 4) ? 8 : 10}>
        {renderSeasonList()}
      </Grid>

      <Grid item xs={(width > 4) ? 4 : 6} className={classes.contentplaylist}>
        <CardContent style={{"height":"11em"}}>
          <Typography component="h5" variant="h5" className={css.PlaylistTitle}>
            {activePlaylist[activeTrack]?.name} S:{activePlaylist[activeTrack]?.season} E:{activePlaylist[activeTrack]?.number} 
          </Typography>
          <div className={css.PlaylistDescription}>
            {activePlaylist[activeTrack].title}
          </div>
        </CardContent>

      </Grid>

      {(width > 4) && <Grid item xs={4}>{renderItemList()}</Grid>}

      <Grid container xs={(width > 4) ? 4 : 6} justifyContent="flex-end">
          <CardMedia
            component={Link}
            to={activeCollection.url}
            className={classes.cover}
            image={'https://cms.starity.com/images/image?url='+activePlaylist[activeTrack].image}
            title=""
            padding="100"
            style={{'marginRight': '2em'}}
          />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.controls}>
          <IconButton aria-label="previous" onClick={() => handleBack()}>
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton>
          <IconButton aria-label="next" onClick={() => handleNext()}>
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton>

          {userLoggedIn &&
            <>
              {(userMedia.hasOwnProperty("Show:"+activeCollection.id) && 
                userMedia["Show:"+activeCollection.id].hasOwnProperty(activePlaylist[activeTrack].season) && 
                userMedia["Show:"+activeCollection.id][activePlaylist[activeTrack].season]?.includes(activePlaylist[activeTrack].number)) 
                ? <IconButton aria-label="previous" onClick={() => handleUserMedia('remove')}><RadioButtonCheckedIcon /></IconButton> 
                : <IconButton aria-label="previous" onClick={() => handleUserMedia('add')}><RadioButtonUncheckedIcon /></IconButton>
              }
            
              <IconButton aria-label="previous" onClick={() => handleUserMedia()}>
                {theme.direction === 'rtl' ? <QueueIcon /> : <QueueIcon />}
              </IconButton>
            </>
          }
        </div>
      </Grid>
    </Grid>
    );
  }

  return (
    <Card className={css.PlayList} ref={targetRef}>
      <Grid container>
        {
        activePlaylist != undefined && (viewMode == "collection" && playlist.length > 1 ? renderCollections() : renderPlaylist())
        }
      </Grid>
  </Card>
  );
}

export default PlayList;

/*
{width <= 4 ? <RadioButtonUncheckedIcon onClick={() => handleNext()} /> :
  <>
    <RadioButtonUncheckedIcon onClick={() => handleNext()} />
    <Typography variant="subtitle1" color="textSecondary">
      Watched
    </Typography>
  </>
}

<IconButton aria-label="play/pause">
  <PlayArrowIcon className={classes.playIcon} />
</IconButton>

*/