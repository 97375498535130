import React from 'react';

import css from '../../../style/components.module.scss';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReadButton from '../../buttons/ReadButton';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import { Link } from "react-router-dom";
import Feed from '../Feed';

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  h2: {
    margin: 0,
    padding: 0,
  },
});

function FeedItemSmall(props) {

  const classes = useStyles();
  let title = props.title;
  let source = "tmz.com";

  const renderAssetAvatars = () => {
    return props.assets.map((asset, index) => (
        <Avatar key={asset.url} component={Link} to={'/' + asset.url} alt={asset.name} src={asset.photo?.url} sx={{ width: 24, height: 24 }} style={{textDecoration: 'none'}}  />
    ));
  };

  const renderCardMedia = () => {
    let imageHeight = "225";
    let imageMaxHeight = "15em";
    let imagePosition = "relative";
    if (props.image) {
      if (props.type === "social") {
        imageHeight = "225";
        imageMaxHeight = "15em";
        imagePosition = "relative";
      }

    if (props.type == "social") {
      title = props.description;
      source = props.title;
    }

      return (
        <CardMedia
          component="img"
          alt=""
          width={"100%"}
          height={"8em"}
          image={props.image}
          title=""
          style={{"float": "left", "width": "15em", "height": "100%", "marginRight": "1em", "maxHeight": "8em", "paddingLeft": ".25em"}}
         />
      );
    }
  };

  return (
    <Card className={css.FeedItemSmall}>

        <a href={props.url} style={{"float": "left"}} target='_blank'>{renderCardMedia()}</a>
        <CardContent style={{"paddingBottom": "0"}}>
            <h2 className={classes.h2}>
              <a href={props.url} target='_blank'>{title}</a>
            </h2>
          <Typography variant="body2" color="textSecondary" component="p">
            {source}
          </Typography>
        </CardContent>

      <CardActions>
        <AvatarGroup size="small" style={{"marginLeft":"auto"}}>
          {renderAssetAvatars()}
        </AvatarGroup>
      </CardActions>
    </Card>
  );
}

export default FeedItemSmall;