import React from 'react';

import { PhotoPlaceholder } from 'react-placeholder-image';
import ProfileItem from './ProfileItem';
import Pagination from '@material-ui/lab/Pagination';

function Profile(props) {

  var profiles = props.profiles.map(function(profile) {
      return <li><ProfileItem name={profile.name} image={profile.image} description={profile.description} /></li>;
  });

  return (
    <div className="Profile Feed">
      <ul>
        {profiles}
      </ul>
    </div>
  );
}

export default Profile;
