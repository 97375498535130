import React,{useEffect, useRef,  useState,} from 'react';
import * as _ from 'underscore';
import css from '../style/components.module.scss';
import { useDispatch, useSelector} from 'react-redux';

import { Search } from '../services/AppService';

import Grid from '@material-ui/core/Grid';
import { TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { AddExperienceAssetsAction, RemoveExperienceAssetsAction } from '../redux/actions/ProfileActions';

function AddAssetBox({experience, setShowAddSources}) {

    const userFollowing = useSelector(state=>state?.userDetails?.userFollowing);
    const dispatcher = useDispatch();
    const [addedAssets, setAddedAssets] = useState(experience?.assets ?? []);
    const [suggestedAssets, setSuggestedAssets] = useState(userFollowing);
    const [searchAssets, setSearchAssets] = useState([]);

    const renderSearchBox = () => {
        return (
        <div>
            <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                onChange={(event) => throttleInputChange(event.target)}
            />
        </div>
        );
    };

    const handleAddAsset = (asset) => {
        dispatcher(AddExperienceAssetsAction(experience?.url, [asset]));
        setAddedAssets([...addedAssets, asset]);
        // remove from suggestions
        setSuggestedAssets(suggestedAssets.filter((item) => item.id !== asset.id));
    };

    const handleRemoveAsset = (asset) => {
        // remove from added
        setAddedAssets(addedAssets.filter((item) => item.id !== asset.id));
        dispatcher(RemoveExperienceAssetsAction(experience?.url, [asset], true));
    };

    const handleSearch = (event) => {
        // search for assets
        Search(event.value).then((res) => {
            setSearchAssets(res);
        }, error => {
            //
        })
    };

    const throttleInputChange = useRef(_.throttle(handleSearch, 1000)).current;

    const renderSearchResults = () => {
        //loop trough userFollowing and list assets
        if (searchAssets?.length > 0) {
            return searchAssets.map((asset, index) => {
                if (index < 20) {
                    return (
                        <Chip
                            className={css.addAssetBoxChip}
                            key={asset.id}
                            avatar={<Avatar alt={asset.name} src={'https://cms.starity.com/images/image?url='+asset?.photo} />}
                            label={asset.name}
                            variant="outlined"
                            style={{"margin": ".25em", "cursor": "pointer"}}
                            onClick={() => handleAddAsset(asset)}
                        />
                    );
                }
            });
        }
    }

    const renderAssetSuggestions = () => {

        //loop trough userFollowing and list assets
        if (userFollowing?.length > 0) {
        return suggestedAssets.map((asset, index) => {
            return (
            <Chip
                className={css.addAssetBoxChip}
                key={asset.id}
                avatar={<Avatar alt={asset.name} src={'https://cms.starity.com/images/image?url='+asset?.photo} />}
                label={asset.name}
                variant="outlined"
                style={{"margin": ".25em", "cursor": "pointer"}}
                onClick={() => handleAddAsset(asset)}
            />
            );
        });
    }
    };

    const renderAddedAssets = () => {
        //loop trough userFollowing and list assets
        if (addedAssets?.length > 0) {
        return addedAssets.map((asset, index) => {
            return (
            <Chip
                className={css.addAssetBoxChip}
                key={asset.id}
                avatar={<Avatar alt={asset.name} src={'https://cms.starity.com/images/image?url='+asset?.photo} />}
                label={asset.name}
                variant="outlined"
                style={{"margin": ".25em"}}
                onClick={() => handleRemoveAsset(asset)}
            />
            );
        });
    }
    };

    //const renderAddAssetBox = () => {
            return (
                <div className={css.addAssetBox}>
                <h3>Add 3 or more people, shows, movies, ect.</h3>
                <Grid container spacing={1} style={{backgroundColor: '#efeded', marginBottom: '1em', overflow: 'hidden', padding: '1em'}}>
                    <Grid item xs={12} md={4} style={{padding: '.5em'}}>
                    <h4>Search</h4>
                    {renderSearchBox()} <br />
                    {searchAssets && renderSearchResults()}
                    </Grid>
                    <Grid item xs={12} md={8} style={{padding: '.5em'}}>
                    <h4>Suggestions</h4>
                    {renderAssetSuggestions()}
                    <br /><br />
                    <h4>Added</h4>
                    {renderAddedAssets()}
                    </Grid>
                    <Grid item xs={12} md={12} style={{padding: '.5em'}}>
                    <Button variant="contained" color="primary" disabled={addedAssets.length < 3} onClick={() => setShowAddSources(false)}>Done</Button>
                    </Grid>
                </Grid>
                </div>
            );
        //}
    //};

}

export default AddAssetBox;