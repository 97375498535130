import React,{ useState, useEffect } from 'react';

import css from '../style/components.module.scss';

import { Button, TextField, Card } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useStyles} from '../Style.js';
import {useDispatch, useSelector} from 'react-redux';
import {LoginAction} from '../redux/actions/AuthActions';
import {useHistory,Link} from 'react-router-dom'; 
import * as ActionTypes from '../redux/ActionTypes';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Login() {
const history = useHistory();
const classes = useStyles();
const dispatcher = useDispatch();
const authResponse = useSelector(state=>state.userAuth.authResponse);

const [fields, setState] = useState({
    email: "",
    password: "",
    });
    const handleFieldChange = e => {
    setState({
        ...fields,
        [e.target.id] : e.target.value
    })
}
const UserLogin = (e) => {
    e.preventDefault();
    dispatcher(LoginAction(fields, history));
};

useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Login", assetType: null, assetId: null});
}, [])

return (
<div className={css.home}>

    {authResponse && authResponse.success == true &&
        <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="error">
            Account was created. Please login.
        </Alert>
    }

    <form onSubmit={UserLogin}>
        <div>
            <TextField
                type="email"
                className={classes.fullWidth}
                required
                margin="normal"
                variant="outlined"
                label="email"
                id="email"
                value={fields.email}
                onChange={handleFieldChange}
            />
        </div>
        <div>
            <TextField
                className={classes.fullWidth}
                label="Password"
                type="password"
                margin="normal"
                variant="outlined"
                required
                id="password"
                value={fields.password}
                onChange={handleFieldChange}
            />
        </div>
        <div>
            <Button
                type="submit"
                className={classes.fullWidth}
                variant="contained"
                color="primary"
                endIcon={<AccountCircleIcon />}
            >
                Login
            </Button>
            <br /><br /><br /><br />
            <div className={classes.linkContainer}>
                <Link to="/user/register">Create Account</Link>
            </div>
        </div>
    </form>

</div>
)
}
export default Login