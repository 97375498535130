import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../Style';

import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FacebookIcon from '@mui/icons-material/Facebook';

import { Link } from 'react-router-dom';
import { Facebook } from '@mui/icons-material';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

function PageFooter(props) {

    const useStyles = makeStyles({
        root: {
            background: '#ccc',
            margin: 0,
            padding: 0,
            paddingTop: '2em',
            paddingLeft: props.open ? drawerWidth + 25 : 65,
            paddingBottom: '10px',
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
        },
        h2: {
            color: '#000',
            margin: 0,
            padding: '.5em',
        },
        h5: {
            margin: 0,
            padding: '.5em',
        },
        star: {
            color: '#365a92',
            marginRight: '.25em',
            marginLeft: '-.2em',
            fontSize: '1em',
            position: 'relative',
            top: '-.1em',
        },
        twitter: {
            position: 'relative',
            top: '.13em',
            fontSize: '1.5em',
            color: '#000',
        }
    });
    const classes = useStyles();
 
    return (
        <div  className={classes.root} >
        <Grid container spacing={0} style={{"maxWidth": "100em", "padding": "1em 2em", "margin": "auto"}} alignItems="flex-start">
            <Grid item xs={12} md={3}>
                <h2 className={classes.h2}><Link to={'/membership'} className={classes.link}><small className={classes.star}>★</small>Fan Membership</Link></h2>
                <h2 className={classes.h2}><Link to={'/blog'} className={classes.link}><small className={classes.star}>★</small>Starity Blog</Link></h2>
            </Grid>
            <Grid item xs={12} md={3}>
                <h2 className={classes.h2}><Link to={'/apps'} className={classes.link}>Starity Apps</Link></h2>
                <h2 className={classes.h2}><Link to={'/company'} className={classes.link}>About Starity</Link></h2>
            </Grid>
            <Grid item xs={12} md={3}>
                <h2 className={classes.h2} style={{marginTop: "-.5em"}}><a href="https://x.com/starityapp" target="_blank" className={classes.link}>@StarityApp <small  className={classes.twitter}>𝕏</small></a></h2>
                <h2 className={classes.h2} style={{marginTop: "-.5em"}}><a href="https://facebook.com/staritydotcom" target="_blank" className={classes.link}>@StarityDotCom <small  className={classes.twitter} style={{"top": "5px", "left": "-3px"}}><FacebookIcon /></small></a></h2>
            </Grid>
            <Grid item xs={12} md={2}>
                <h5 className={classes.h5}><Link to={'/privacy'} className={classes.link}>Privacy &amp; Rights</Link></h5>
                <h5 className={classes.h5}><Link to={'/terms'} className={classes.link}>Terms of Service</Link></h5>
            </Grid>
            <Grid item xs={10} md={10} style={{"marginTop": "2em"}}>
                <small>&copy; 2023 Starity Entertainment LLC. Created @ NetixLabs.</small>
            </Grid>
        </Grid>
        </div>
    );
}

export default PageFooter;