import React, { useReducer, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import { useStyles, } from '../Style';

import Widget from '../components/widgets/Widget';
import Profile from '../components/widgets/Profile';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

//Images & Icons
import ThumbUp from '@material-ui/icons/ThumbUpAlt';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import * as ActionTypes from '../redux/ActionTypes';

function Location() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

    useEffect(() => {
      dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Location", assetType: null, assetId: null});
    }, []);

    //FeedModel
    const feed1 = { "title":"Headline One", "description":"The Canadian pipeline company that had long sought to build the Keystone XL pipeline announced Wednesday that it had terminated the embattled project, which would have carried petroleum from Canadian tar sands to Nebraska.", "image":"/images/contemplative-reptile.jpg" };
    const feed2 = { "title":"Headline Two", "description":"The Canadian pipeline company that had long sought to build the Keystone XL pipeline announced Wednesday that it had terminated the embattled project, which would have carried petroleum from Canadian tar sands to Nebraska.", "image":"/images/news3.webp" };
    const feed3 = { "title":"Headline Three", "description":"The Canadian pipeline company that had long sought to build the Keystone XL pipeline announced Wednesday that it had terminated the embattled project, which would have carried petroleum from Canadian tar sands to Nebraska.", "image":"/images/news1.jpeg" };
    const feed4 = { "title":"Headline Four", "description":"The Canadian pipeline company that had long sought to build the Keystone XL pipeline announced Wednesday that it had terminated the embattled project, which would have carried petroleum from Canadian tar sands to Nebraska.", "image":"/images/news2.webp" };
    const feed5 = { "title":"Headline Five", "description":"The Canadian pipeline company that had long sought to build the Keystone XL pipeline announced Wednesday that it had terminated the embattled project, which would have carried petroleum from Canadian tar sands to Nebraska.", "image":"/images/news1.jpeg" };
    const items = [feed1, feed2, feed3, feed4, feed5];

    //ProfileModel
    const profile1 = {"name":"Name One", "image":"/images/profile1.jpeg", "description":"Position One"};
    const profile2 = {"name":"Name Two", "image":"/images/profile2.jpeg", "description":"Position Two"};
    const profile3 = {"name":"Name Three", "image":"/images/profile3.jpeg", "description":"Position Three"};
    const profile4 = {"name":"Name Four", "image":"/images/profile1.jpeg", "description":"Position One"};
    const profile5 = {"name":"Name Five", "image":"/images/profile2.jpeg", "description":"Position Two"};
    const profile6 = {"name":"Name Six", "image":"/images/profile3.jpeg", "description":"Position Three"};
    const profiles = [profile1, profile2, profile3, profile4, profile5, profile6];

    //ScoreBoardModel
    const score1 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Phoenix Rising FC", "score":1, "win":true, "image":"/images/team5.png"},
                "team2":{"id":null, "name":"Team Two", "score":1, "win":false, "image":"/images/team2.png"} 
                };
    const score2 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Phoenix Rising FC", "score":2, "win":false, "image":"/images/team5.png"},
                "team2":{"id":null, "name":"Team Four", "score":1, "win":false, "image":"/images/team4.png"} 
                };
    const score3 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Phoenix Rising FC", "score":2, "win":false, "image":"/images/team5.png"},
                "team2":{"id":null, "name":"Team Four", "score":1, "win":false, "image":"/images/team4.png"} 
                };
    const score4 = {
                "match":{"league_id":null, "final":true, "startDate":null, "endDate":null, "location":null, "location_id":null},
                "team1":{"id":null, "name":"Phoenix Rising FC", "score":2, "win":false, "image":"/images/team5.png"},
                "team2":{"id":null, "name":"Team Four", "score":1, "win":false, "image":"/images/team4.png"} 
                };
    const scores = [score1, score2, score3, score4];

  return (
      <div>
      <div className={classes.subTitle}>
        <Chip size="medium" label="W / W / L / D / W" avatar={<Avatar><ThumbUp /></Avatar>} /> &nbsp;
        <Chip size="medium" label="4-1-1" avatar={<Avatar src="/images/league1.png"></Avatar>} /> &nbsp;
        <Chip size="medium" avatar={<Avatar>1st</Avatar>} label="USL Championship" />
      </div>
    
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Widget widgetName="Score Board" type="scoreboard" matches={scores} />
            <Widget widgetName="Calendar" type="calendar" />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget widgetName="Feed" type="feed" items={items} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget widgetName="Active Roster">
              <Profile profiles={profiles} />
            </Widget>
          </Grid>
        </Grid>
    </div>
    </div>
  );
}

export default Location;