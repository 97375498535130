import { withStyles } from '@material-ui/core/styles';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import { NoEncryption } from '@mui/icons-material';

const StyledBadge = withStyles((theme) => ({
  root: {
    marginRight: '.5em',
  },
  badge: {
    right: -7,
    top: 7,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 3px',
  },
}))(Badge);

const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: '#365a92',
    },
  },
  components: {
    MuiDrawer: {
      backgroud: '#000000',
    }
  }
});

const drawerWidth = 240;

//COLORS
const red1 = "#D44D5C";
const navy1 = "#0B0014";
const navy2 = "#0b2a40";
//const gray = "#cccccc";
const gray = "#f2efef";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&.MuiAppBar-colorPrimary": {
      background: gray,
      color: '#773344',
    },
    // Adjust for thin menu
    width: `calc(100% - 41px)`,
    "&.MuiAppBar-root": {
      width: `calc(100% - 41px)`,
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&.MuiAppBar-root": {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  card: {
    padding: '1em',
  },
  menuButton: {
    "&.MuiIconButton-root": {
      color: '#365a92',
    },
  },
  subTitle: {
    fontSize: '1.2em',
    marginLeft: '.5em',
    marginBottom: '.75em',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: '#000000',
  },
  drawerOpen: {
    overflow: 'hidden',
    background: '#0B0014',
    color: '#ffffff',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiListItemIcon-root": {
      color: "#ccc",
      background: "inherit",
      minWidth: "2.5em",
      display: "none",
    },
    
    "& .MuiPaper-root": {
      color: "inherit",
      background: "inherit"
    },
  },
  drawerClose: {
    background: '#0B0014',
    color: '#ffffff',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5) + 1,
    },
    "& .MuiListItemIcon-root": {
      color: "#ccc"
    },
    "& .MuiSvgIcon-root": {
      width: ".8em",
      height: ".8em"
    },
    "& .MuiPaper-root": {
      background: "inherit"
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  divider: {
    "&.MuiDivider-root": {
      width: '230px',
      marginLeft: '5%',
      backgroundColor: '#382a2a',
    }
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    overflowX: 'hidden',
  },
  contentplaylist: {
      //background: '#D44D5C',
      "& h5": {
        fontSize: '1.2em',
        //color: '#ffffff',
      }
  },
  listItem: {
    "&.MuiListItem-root": {
      padding: '0 1em',
    },
  },
  experiences: {
    "& h3": {
      fontSize: "1.3em",
    },
  },
  listItemIcon: {
    minWidth: '2em',
  },
  listItemText: {
    fontSize: "1em",
  },
  largeIcon: {
    width: "3em",
    height: "3em",
  },
  smallIcon: {
    width: ".65em",
    height: ".65em",
  },
  playlistroot: {
    display: 'flex',
    //background: '#D44D5C',
  },
  cover: {
    width: 145,
    height: 145,
    margin: '1em',
  },
  controls: {
    //background: '#D44D5C',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  collectionTag: {
    textAlign: 'center',
    padding: '5px',
    background: '#ccc',
    marginTop: '0',
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  widget: {
      marginBottom: '5em',
  },
  modalPaper: {
    position: 'absolute',
    width: '70%',
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  nav: {
    maxWidth: '5em',
  },
}));

export {
  StyledBadge,
  theme,
  drawerWidth,
  useStyles,
}