import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles, } from '../Style';

import * as ActionTypes from '../redux/ActionTypes';


function Sponsor() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h4>Reach Your Fans</h4>
      <h1><span style={{"color":"#f50057"}}>★</span>Sponsored Experiences.</h1>
      <p>
        * Verified Profile Customizations.<br />
        * Sponsored Experiences + Listings.<br />
        * Priority Support.<br /><br />
      </p>
      <p>
        <Button variant="outlined" size="large"><strong>$495</strong> &nbsp; <small>/Get Started</small></Button> &nbsp;
        <Button variant="outlined" size="large"><strong>Contact</strong> &nbsp; <small>/Agency Access</small></Button>
      </p>
      <br />
      <br />
    </div>
  );
}

export default Sponsor;