import request from "./request";

export default class ExperienceService {
  static getExperience(url) {
    return request({
      url: "/experience/" + url,
      method: "GET",
    });
  }
  static getExperiences(url) {
    return request({
      url: "/",
      method: "GET"
    });
  }
  static getCanvas(url, assets, blocks) {
    return request({
      url: "/experience/" + url + "/canvas?viewport=web&assets=" + encodeURIComponent(assets) + "&blocks=" + blocks,
      method: "GET",
    });
  }
}
