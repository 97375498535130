import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { LoadProfileAction } from '../../redux/actions/ProfileActions';

import css from '../../style/components.module.scss';
import { styled } from '@mui/material/styles';
import { useStyles } from '../../Style';

import { AddExperienceAction } from "../../redux/actions/ProfileActions";

// Modal
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


export default function AddExperienceModal(props) {

    const dispatcher = useDispatch();
    const classes = useStyles();

    const [newExperienceName, setNewExperienceName] = React.useState('');
    const [newExperienceDescription, setNewExperienceDescription] = React.useState('');
    const [newExperienceLayout, setNewExperienceLayout] = React.useState('dashboard');
    const [modalStyle] = React.useState(getModalStyle);

    const [modalStep, setModalStep] = React.useState(1);

    function getModalStyle() {
      const top = 35;
      const left = 50;
    
      return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        borderRadius: '1em',
      };
    }

    const steps = ['Experience Details', 'Experience Layout'];

    const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    }));

    const handleAddExperience = () => {
        dispatcher(AddExperienceAction(newExperienceName, newExperienceDescription, newExperienceLayout));
        setTimeout(() => {
            dispatcher(LoadProfileAction());
        }, 100);
        props.handleCloseModal();
    }

    const renderModelStep = () => {
        switch (modalStep) {
        case 1:
            return (
            <div>
                <h1>Add Experience</h1>
                <h2>Experience Details</h2>
                <Grid container spacing={3}>
                <Grid item xs={12} md={6} spacing={0}>
                    <TextField 
                    value={newExperienceName}
                    onChange={(e) => {
                        setNewExperienceName(e.target.value);
                    }}
                    fullWidth id="experience_name" label="Experience Name*" variant="standard" 
                    />
                    <br /><br />
                    <TextField fullWidth
                    id="experience_description"
                    label="Description (optional)"
                    multiline
                    rows={4}
                    variant="standard"
                    />
                </Grid>
                <Grid item xs={12} md={6} spacing={0}>
                    <ToggleButtonGroup
                    color="primary"
                    value="public"
                    exclusive
                    onChange="" //todo
                    aria-label="Platform"
                    >
                    <ToggleButton value="public">Public</ToggleButton>
                    <ToggleButton value="private">Private</ToggleButton>
                    </ToggleButtonGroup>
                    <p>This experience will be public and visible to anyone. <em>(Private option coming soon)</em></p>             
                </Grid>
                </Grid>
            </div>
            );
        case 2:
            return (
            <div>
                <h1>Add Experience</h1>
                <h2>Experience Layout</h2>
                <p>Note: You can edit block settings or add/remove blocks after creating a new experience. (Layout options coming soon)</p>
                <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="dashboard"
                name="radio-buttons-group"
                onChange={(e) => {
                    setNewExperienceLayout(e.target.value);
                }}
                >
                <Grid container spacing={3}>
                <Grid item xs={12} md={6} spacing={0}>
                    <FormGroup>
                    <FormControlLabel value="dashboard" control={<Radio />} label="Entertainment Dashboard" />
                    <small>Everything all in one place.</small>

                    <FormControlLabel value="sportsdashboard" control={<Radio />} label="Sports Dashboard" disabled={true} />
                    <small>Sports scores, rosters and more.</small>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} spacing={0}>
                    <FormGroup>
                    <FormControlLabel value="watchlist" control={<Radio />} label="What to Watch" disabled={true}/>
                    <small>User watch list of shows, clips and more.</small>

                    <FormControlLabel value="feedcentric" control={<Radio />} label="Feed Centric" disabled={true} />
                    <small>News and social take main focus.</small>
                    </FormGroup>
                </Grid>
                </Grid>
                </RadioGroup>
            </div>
            );
        default:
            //props.setOpenModal(false);
            return null;
        }
    }


    return (
    <Modal
    className={css.modal}
    open={props.openModal}
    onClose={props.handleCloseModal}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    >
    <div style={modalStyle} className={classes.modalPaper}>
    <div className={css.ModalContent}>
        {renderModelStep()}
    </div>
    <Grid className={css.ModalFooter} container spacing={3} justifyContent="flex-end">
        <Grid item xs={12} md={6}>                
        <Stepper activeStep={modalStep-1} className={css.ModalStepper} >
            {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
                <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
            );
            })}
        </Stepper>
        </Grid>
        <Grid item xs={12} md={6} direction="column" alignItems="flex-start" justifyContent="flex-end">
        {modalStep > 1 && <span><Button variant="contained" color="primary" onClick={() => setModalStep(modalStep - 1)}>Back</Button> &nbsp;</span>}
        {modalStep < steps.length && modalStep >= 1 && <Button variant="contained" color="primary" disabled={newExperienceName.length < 6} onClick={() => setModalStep(modalStep + 1)}>Next</Button>}
        {modalStep == steps.length && <Button variant="contained" color="primary" onClick={(e) => handleAddExperience()}>Add</Button>}
        </Grid>
    </Grid>
    </div>
    </Modal>
    );
}