import React from 'react';
import css from '../../style/components.module.scss';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  h4small: {
    fontSize: "1em",
    fontWeight: "500",
  },
  h4underline: {
    fontSize: "1.2em",
    margin: ".25em",
    fontWeight: "500",
    textDecoration: "underline",
  },
  h4: {
    fontSize: "1.2em",
    margin: ".25em",
    fontWeight: "500",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  win: {
    fontColor: "blue",
  },
}));

function ScoreBoard({scoreboardData, id, width, height, ...props}) {
  const classes = useStyles();

  var matches = scoreboardData?.map(function(match) {
      return <ScoreBoardItem key={match.id} match={match} />;
  });

  return (
    <div className={classes.container}>
      {matches ?? "No matches found"}
    </div>
  );
}

function ScoreBoardItem(props) {
  
  const classes = useStyles();

  return (
    <Card className={css.ScoreBoardItem} container>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={2}>
              <Avatar alt="-" src={'https://cms.starity.com/images/image?url='+props.match.team1.photo.url} className={css.logo} />
            </Grid>
            <Grid item xs={10}>
              <h4 className={classes.h4}>{props.match.team1.name}</h4>
            </Grid>
            <Grid item xs={2}>
              <Avatar alt="-" src={'https://cms.starity.com/images/image?url='+props.match.team2.photo.url} className={css.logo} />
            </Grid>
            <Grid item xs={10}>
              <h4 className={classes.h4}>{props.match.team2.name}</h4>
            </Grid>
          </Grid>
        </Grid>
        {props.match.status == "Final" && 
        <Grid item xs={2}>
          <h4 className={classes.h4}>{props.match.team1_score}</h4>
          <h4 className={classes.h4}><span className={classes.win}>{props.match.team2_score}</span></h4>
        </Grid>
        }
        <Grid item xs={props.match.status == "Final" ? 2 : 4}>
          <h4 className={props.match.status == "Final" ? classes.h4 : classes.h4small}>{props.match.status}</h4>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ScoreBoard;