import HttpService from './HttpService';
import axios from 'axios'
axios.defaults.withCredentials = true;
//axios.defaults.baseURL = "https://cms.starity.com";

export const LoadProfile = async () => {
    const http = new HttpService();
    let profileUrl = "user";
    const tokenId = "user-token";
    return http.getData(profileUrl, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const LoadPinned = async () => {
    const http = new HttpService();
    let serviceUrl = "pinned";
    const tokenId = "user-token";
    return http.getData(serviceUrl, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const GetFollowingExperience = async () => {
    const http = new HttpService();
    let followUrl = "follow-experience";
    const tokenId = "user-token";
    try {
        const data = await http.getData(followUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const AddExperience = async (name, description, layout) => {
    const http = new HttpService();
    let apiUrl = "experience/add";
    const tokenId = "user-token";
    const postInfo = {name: name, description: description, layout: layout};
    try {
        const data = await http.postData(postInfo, apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const UpdateExperience = async (url, layout, widgets, settings) => {

    const http = new HttpService();
    let updateUrl = "experience/" + url +  "/update";
    const tokenId = "user-token";
    const postInfo = {url: url, grid_layout: layout, widgets: widgets, widget_settings: settings};
    try {
        const data = await http.postData(postInfo, updateUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const AddExperienceAssets = async (url, assets) => {
    const http = new HttpService();
    let apiUrl = "experience/" + url + "/assets/add";
    const tokenId = "user-token";
    const postInfo = {assets: assets};
    try {
        const data = await http.postData(postInfo, apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const RemoveExperienceAssets = async (url, assets) => {
    const http = new HttpService();
    let apiUrl = "experience/" + url + "/assets/remove";
    const tokenId = "user-token";
    const postInfo = {assets: assets};
    try {
        const data = await http.postData(postInfo, apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const LoadFollowing = async () => {
    const http = new HttpService();
    let profileUrl = "following";
    const tokenId = "user-token";
    return http.getData(profileUrl, tokenId).then(data => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const Follow = async (assetType, assetId) => {
    const http = new HttpService();
    let followUrl = "follow";
    const tokenId = "user-token";
    const postInfo = {assetType: assetType, assetId: assetId};
    return http.postData(postInfo, followUrl, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const Pin = async (assetType, assetId) => {
    const http = new HttpService();
    let apiUrl = "pin";
    const tokenId = "user-token";
    const postInfo = {assetType: assetType, assetId: assetId};
    return http.postData(postInfo, apiUrl, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error; 
    });
}

export const GetUserMedia = async (assetType, assetIdArray) => {
    const http = new HttpService();
    let apiUrl = "user-media/search";
    const tokenId = "user-token";
    let getInfo = '';
    if (assetType != null && assetIdArray != null) {
        const getInfo = "?asset_type=" + assetType + "&asset_id=" + [assetIdArray];
    }
    return http.getData(apiUrl+getInfo, tokenId).then((data) => {
        return data;
    }).catch((error) => {
        return error;
    });
}

export const AddUserMedia = async (assetType, assetId, mediaJson) => {
    const http = new HttpService();
    let apiUrl = "user-media/add";
    const tokenId = "user-token";
    const postInfo = {asset_type: assetType, asset_id: assetId, media: mediaJson};
    try {
        const data = await http.postData(postInfo, apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}

export const RemoveUserMedia = async (assetType, assetId, mediaJson) => {
    const http = new HttpService();
    let apiUrl = "user-media/remove";
    const tokenId = "user-token";
    const postInfo = {asset_type: assetType, asset_id: assetId, media: mediaJson};
    try {
        const data = await http.postData(postInfo, apiUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}