import request from "./request";

export default class PersonService {
  static getPerson(url) {
    return request({
      url: "/people/person/" + url,
      method: "GET"
    });
  }
  static getPeople() {
    return request({
      url: "/",
      method: "GET"
    });
  }
}
