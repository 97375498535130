import React,{ useEffect, useState } from 'react';
import { useQuery } from 'react-query'
import { useDispatch, useSelector} from 'react-redux';
import { useParams } from "react-router-dom";
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import css from '../style/components.module.scss';

import Widget from '../components/widgets/Widget';
import Profile from '../components/widgets/Profile';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

//Images & Icons
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import TvOffIcon from '@mui/icons-material/TvOff';
import AlarmIcon from '@mui/icons-material/Alarm';

import { useStyles, } from '../Style';

import ShowService from "../api/ShowService";
import * as ActionTypes from '../redux/ActionTypes';

export default function Show() {
  const classes = useStyles();
  const theme = useTheme();
  let { url } = useParams();

  const { data: showData, isLoading: isLoadingShow, isSuccess } = useQuery(
    ["show-" + url],
    () => ShowService.getShow(url),
    {
      cacheTime: 60 * 1000,
      staleTime: 30 * 1000
    }
  );

  const [ pageSet, setPageSet ] = useState(false);

  const userDetails = useSelector(state=>state.userDetails.userProfile);
  const userLoggedIn = useSelector(state=>state.userAuth.loggedIn);
  const userFollowing = useSelector(state=>state.userDetails.userFollowing);

  const dispatcher = useDispatch();
  useEffect(() => {
    if (pageSet !== show?.name) {
      dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: show?.name, assetType: "Show", assetId: show?.id});
      setPageSet(show?.name);
    }  
    return () => {};
  }, [])
    
    const show = isSuccess ? showData.data : {};
    const asset = JSON.stringify([{"type" : "Show", "id": show.id}]);

    const renderPhoto = () => {
      if (show?.photo?.url) {
        return (
          <span>
            <img
              alt="Profile"
              src={'https://cms.starity.com/images/image?url='+show.photo.url}
              width={'100%'}
            />
            <br /><br />
          </span>
        );  
      }
    }

    if (!isSuccess) {
      return "Loading...";
    } else {
      if (pageSet !== show?.name) {
        dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: show?.name, assetType: "Show", assetId: show?.id});
        setPageSet(show?.name);
      }  
    }

  return (
    <div className={css.page}>
      <div className={classes.subTitle}>
        <Chip size="medium" label={isSuccess && show.status} avatar={<Avatar>{(isSuccess && show.status == "Running") ? <LiveTvIcon /> : <TvOffIcon /> }</Avatar>} /> &nbsp;
        {isSuccess && show.status=="Running" && show.airs != null && <Chip size="medium" label={show.airs} avatar={<Avatar><AlarmIcon /></Avatar>} /> }
      </div>
    
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <div>
              <Widget widgetName="Episodes" key={show.url + 'playlist'} type="playlist" assets={asset} widget={{"i": "showPlaylist" + show.url, "type": "playlist"}} id={'showPlaylist' + show.url} width={4} />
            </div>
            <div style={{height: "30em", marginTop: "1em"}} > 
              <Widget key={show.url + 'cal'} widgetName="Calendar" type="calendar"  assets={asset}  widget={{"i": "showCal" + show.url, "type": "calendar"}} id={'showCal' + show.url} width={4} height={2} />
            </div>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget key={show.url + 'feed'} widgetName="Feed" type="feed" width={4} height={8} assets={asset} widget={{"i": "showFeed" + show.url, "type": "feed"}} id={'showFeed' + show.url}  />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Widget key={show.url + 'cast'} widgetName={isSuccess && show.name + " Cast"} type="list" width={4} height={6} assets={asset}  widget={{"i": "showCast" + show.url, "type": "list"}} id={'showCast' + show.url} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}