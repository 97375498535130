import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import css from '../style/components.module.scss';

import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../Style';
import CardPage from '../components/CardPage';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as ActionTypes from '../redux/ActionTypes';


function Discovery() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h1>Welcome Back.</h1>
      <h3>Discovery Page</h3>
      <hr />
      <br />

      <h4>Check Out Some Examples:</h4>

      <br /><br />
      <hr />
      <br /><br />

      <h4>Don't Fear Reading The Comment Section Again</h4>
      <h1>Find Your Club Crew.</h1>
      <h3>Limit comments and reactions from only your subscribed clubs of similar interests. Troll &amp; bot free.</h3>

      <br /><br />
      <br /><br />

    </div>
  );
}

export default Discovery;