import React, { useEffect } from 'react';
import { useStyles } from '../Style';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory,Link} from 'react-router-dom';

import AddExperienceModal from './modals/AddExperience';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

//Images & Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StarIcon from '@material-ui/icons/Star';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BadgeIcon from '@mui/icons-material/Badge';
import FaceIcon from '@mui/icons-material/Face';
import SportsIcon from '@mui/icons-material/Sports';
import TvIcon from '@mui/icons-material/Tv';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useWindowDimensions } from '../App';

function Nav(props) {

  const getIcon = (type,custom=null,url=null) => {
    switch(type) { 
      case "Star":
        return <FaceIcon /> 
      case "Person":
        return <FaceIcon />
      case "Show":   
        return <TvIcon />
      case "Team":
        return <SportsIcon />
      case "VideoGame":
        return <SportsEsportsIcon />
      default:
        return <SportsEsportsIcon />
    }
  }
  
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const authResponse = useSelector(state=>state.userAuth.authResponse);
  const userPinned = props.userPinned;
  const userExperiences = props.userExperiences;
  const userLoggedIn = useSelector(state=>state.userDetails.userLoggedIn);
  
  //Modal control + state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
      setOpenModal(true);
  };
  const handleCloseModal = () => {
      setOpenModal(false);
  };

  const handleLinkClick = () => {
    props.setOpen(!props.isMobile);
  }

  const token = localStorage.getItem('user-token');
  
  useEffect(() => {
    if(authResponse !== "" && authResponse.success === true){
      localStorage.removeItem('user-token');
    }

    return () => {};
  },[authResponse]);

  const renderLoggedOutNav = () => {
    if(userLoggedIn === false){
      return (
        <div>
          <ListItem>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Examples:" />
          </ListItem>

          <ListItem component={Link} to={'/x/100-true-crime-6468690e74217'} className={classes.listItem} button key="example1" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="100% True Crime" />
          </ListItem>
          <ListItem component={Link} to={'/x/hiphop-645447351df81'} className={classes.listItem} button key="example2" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="Most Popular Hip-Hop" />
          </ListItem>
          <ListItem component={Link} to={'/x/flashback-2000-6458ba9ace14e'} className={classes.listItem} button key="example3" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="Flashback ~2000" />
          </ListItem>
          <ListItem component={Link} to={'/x/usa-soccer-64a3dd69c333d'} className={classes.listItem} button key="example4" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="USA Soccer" />
          </ListItem>
          <ListItem component={Link} to={'/x/xxl-freshman-class-649a9ac654bd6'} className={classes.listItem} button key="example5" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="XXL Freshman Class 2023" />
          </ListItem>
          <ListItem component={Link} to={'/x/2024-nfl-draft-prospects-64a3ddc416861'} className={classes.listItem} button key="example6" onClick={() => handleLinkClick()}>
            <ListItemIcon className={classes.listItemIcon}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="2024 NFL Draft Prospects" />
          </ListItem>
        </div>
      );
    }
  }


  var pinned = userPinned.length > 0 && userPinned.map(function(item) {
    return (
      <ListItem component={Link} to={item.asset_url} className={classes.listItem} button key={item.asset_type + item.asset_id} onClick={() => handleLinkClick()}>
        <ListItemIcon className={[classes.listItemIcon, classes.hide].join(' ')}>{getIcon(item.asset_type)}</ListItemIcon>
        <ListItemText className={classes.listItemText} disableTypography={true} primary={item.asset_name} />
      </ListItem>
    );
  });

  var experiences = userExperiences?.length > 0 && userExperiences?.map(function(item) {
    return (
      <ListItem component={Link} to={item.url} className={classes.listItem} button key={item.url} onClick={() => handleLinkClick()}>
        <ListItemIcon className={classes.listItemIconHide}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
        <ListItemText className={classes.listItemText} disableTypography={true} primary={item.name} />
      </ListItem>
    );
  });

  return (
    <div className={classes.nav}>
      <Divider className={classes.divider} />
      <List>
        <ListItem component={Link} to={'/trending'} className={classes.listItem} button key="Trending" onClick={() => handleLinkClick()}>
          <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><TrendingUpIcon className={classes.smallIcon} /></ListItemIcon>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Trending" />
        </ListItem>
        {token !== null && token !== "" && <ListItem component={Link} to={'/following'} className={classes.listItem} button key="Following" onClick={() => handleLinkClick()}>
          <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><StarIcon  className={classes.smallIcon} /></ListItemIcon>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Following" />
        </ListItem>}

      </List>
    <Divider className={classes.divider} />
    <List>
      {experiences}
      {pinned}
      {renderLoggedOutNav()}
    </List>
    <Divider className={classes.divider} />
    <List>

    {
        token !== null && token !== "" ?
        <div>
        <ListItem onClick={handleOpenModal} className={classes.listItem} button key="experiencetest2">
            <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><AddBoxIcon  className={classes.smallIcon} /></ListItemIcon>
            <ListItemText className={classes.listItemText} disableTypography={true} primary="Add Experience" />
        </ListItem>
        <ListItem component={Link} to={'/user/view-profile'} className={classes.listItem} button key="Profile" onClick={() => handleLinkClick()}>
          <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><BadgeIcon className={classes.smallIcon} /></ListItemIcon>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Account Settings" />
        </ListItem>
        </div> :
        <>
        <ListItem component={Link} to={'/user/login'} className={classes.listItem} button key="Login" onClick={() => handleLinkClick()}>
          <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><BadgeIcon className={classes.smallIcon} /></ListItemIcon>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Login" />
        </ListItem>
        <ListItem component={Link} to={'/user/register'} className={classes.listItem} button key="CreateAccount" onClick={() => handleLinkClick()}>
          <ListItemIcon className={classes.listItemIcon} style={{"display": "inline-block"}}><AddCircleIcon className={classes.smallIcon} /></ListItemIcon>
          <ListItemText className={classes.listItemText} disableTypography={true} primary="Create Account" />
        </ListItem>
        </>
    }
    </List>

    <AddExperienceModal openModal={openModal} handleCloseModal={handleCloseModal} />

  </div>
  );
}

export default Nav;