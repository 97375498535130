import React from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { UpdateExperienceAction } from "../redux/actions/ProfileActions";

import css from '../style/components.module.scss';

import { useStyles, } from '../Style';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import Modal from '@material-ui/core/Modal';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCardIcon from '@mui/icons-material/AddCard';
import CheckIcon from '@mui/icons-material/Check';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

function getModalStyle() {
  const top = 35;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    borderRadius: '1em',
  };
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const steps = ['Select Widget Type', 'Choose Widget Content', 'Modify Widget Settings'];


function WidgetSettingsButton(props) {

  const classes = useStyles();
  const dispatcher = useDispatch();

  const [modalStyle] = React.useState(getModalStyle);
  const [isEditable, setIsEditable ] = [props.isEditable, props.setIsEditable];
  const [openModal, setOpenModal] = React.useState(false);
  const [modalStep, setModalStep] = React.useState(1);

  // Widget to be Added
  const [widgetToAddType, setWidgetToAddType] = React.useState('list');
  const [widgetToAddContent, setWidgetToAddContent] = React.useState({});
  const [widgetToAddName, setWidgetToAddName] = React.useState('Widget To Add');
  const [widgetToAddSettings, setWidgetToAddSettings] = React.useState({});

  const [widgetArray, setWidgetArray] = [props.widgetArray, props.setWidgetArray];

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
      setOpenModal(false);
  };

  const handleAdd = (widgetType) => {
    setWidgetArray([
      ...widgetArray,
      { i: "widget" + (widgetArray.length + 1), x: 0, y: 0, w: 2, h: 2, type: widgetType },
    ]);
    setOpenModal(false);
  };

  const handleWidgetToAdd = (type, content, name, settings) => {
    setWidgetToAddType(type);
    setWidgetToAddContent(content);
    setWidgetToAddName(name);
    setWidgetToAddSettings(settings);
    setModalStep(modalStep + 1);
  }

  const [alignment, setAlignment] = React.useState('fit');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // add [widgetToAdd] to [widgetArray]
  const handleAddWidget = () => {
    const tempArray = props.widgetArray;
    tempArray.push({ i: "widget" + (widgetArray.length + 1), x: 0, y: 0, w: 4, h: 4, type: widgetToAddType, content: widgetToAddContent, name: widgetToAddName, assets: [] });
    setWidgetArray(tempArray);
    dispatcher(UpdateExperienceAction(props.experienceUrl, props.layouts, props.widgetArray, null));
    setModalStep(1);
    setOpenModal(false);
  }

  const handleAddSources = () => {
    props.setShowAddSources(!props.showAddSources);
  }

  const renderModelStep = () => {
    switch (modalStep) {
      case 1:
        return (
          <div>
            <h2>Widget Type</h2>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Item onClick={() => handleWidgetToAdd("feed")}><h2>Feed</h2></Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item onClick={() => handleWidgetToAdd("playlist")}><h2>Playlist</h2></Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item onClick={() => handleWidgetToAdd("scoreboard")}><h2>Scoreboard</h2></Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item onClick={() => handleWidgetToAdd("calendar")}><h2>Calendar</h2></Item>
              </Grid>
              <Grid item xs={12} md={6}>
                <Item onClick={() => handleWidgetToAdd("list")}><h2>List</h2></Item>
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div>
            <h2>Widget Content</h2>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Item onClick={() => handleWidgetToAdd(widgetToAddType, "all")}><h2>Use all from page</h2></Item>
              </Grid>
            </Grid>
          </div>
        );
      case 3:
        return (
          <div>
            <h2>Widget Settings</h2>
            <FormControl fullWidth sx={{ m: 1 }} >
              <TextField id="name" label="Name/Heading" variant="outlined" onInput={e => setWidgetToAddName(e.target.value)} />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} >
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton value="fit">Fit to height</ToggleButton>
                <ToggleButton value="height">Scroll in wiget</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </div>
        );
      default:
        setOpenModal(false);
        return null;
    }
  }

  return (
    <>
        <Button style={{"color":"#365a92"}} startIcon={isEditable ? <CheckIcon /> : <WidgetsIcon /> } onClick={() => { isEditable ? setIsEditable(false) : setIsEditable(true); }}>
          <span style={{"color":"#773344"}}>{ isEditable ? "Done" : "Edit" }</span>
        </Button>
        {props.isEditable && <Button variant={props.showAddSources && "outlined"} style={{"color":"#365a92"}} startIcon={<PlaylistAddIcon />} onClick={handleAddSources}><span style={{"color":"#773344"}}>Sources</span></Button>}
        {props.isEditable && <Button style={{"color":"#365a92"}} startIcon={<AddCardIcon />} onClick={handleOpenModal}><span style={{"color":"#773344", "whiteSpace": "nowrap"}}>Add Block</span></Button>}

      <Modal
          className={css.modal}
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.modalPaper}>
            <div className={css.ModalContent}>
              {renderModelStep()}
            </div>
            <Grid className={css.ModalFooter} container spacing={3} justifyContent="flex-end">
              <Grid item xs={12} md={8}>                
                <Stepper activeStep={modalStep-1} className={css.ModalStepper} >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid item xs={12} md={4} direction="column" alignItems="flex-start" justifyContent="flex-end">
                {/*modalStep > 1 && <Button variant="contained" color="primary" onClick={() => setModalStep(modalStep - 1)}>Back</Button>*/}
                {modalStep < 3 && modalStep > 1 && <Button variant="contained" color="primary" onClick={() => setModalStep(modalStep + 1)}>Next</Button>}
                {modalStep == 3 && <Button variant="contained" color="primary" onClick={() => handleAddWidget()}>Add Widget</Button>}
              </Grid>
            </Grid>
          </div>
      </Modal>
    </>
  );
}

export default WidgetSettingsButton;