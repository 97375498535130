import React from 'react';
import { useState, useReducer, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query'
import { useDispatch, useSelector} from 'react-redux';

import AssetBar from '../components/AssetBar.tsx';
import AddAssetBox from '../components/AddAssetBox.js';

import ExperienceService from '../api/ExperienceService';

//Layout & Style
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { StyledBadge, useStyles, } from '../Style';

//Images & Icons
import Canvas from "../components/Canvas";
import WidgetSettingsButton from '../components/WidgetSettingsButton';
import * as ActionTypes from '../redux/ActionTypes';


function Experience() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();
  let { url } = useParams();
  if (url === undefined) {
    url = "trending";
  }

  const [ pageSet, setPageSet ] = useState(false);
  const { data: experienceData, isLoading, isSuccess } = useQuery(
    "experience-" + url,
    () => ExperienceService.getExperience(url),
    {
      staleTime: 1 * 60 * 1000, 
      cacheTime: 5 * 60 * 1000
    }
  );
  const experience = isSuccess ? experienceData : {};
  const token = localStorage.getItem('user-token');
  const isLoggedIn = token !== undefined && token !== null && token !== "";
  const userDetails = useSelector(state=>state?.userDetails?.userProfile);
  // check if user owns the experience
  const isOwner = isLoggedIn && experience?.owner_id === userDetails?.id;

  const [isEditable, setIsEditable ] = useState(false);
  const [showAddSources, setShowAddSources] = useState(false);
  const [widgetArray, setWidgetArray] = useState({});
  const [layouts, setLayouts] = useState();

  if (!isSuccess && widgetArray === undefined) {
    return "Loading...";
  } else {
    if (pageSet !== experience?.name) {
      dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: experience?.name, assetType: null, assetId: null});
      setPageSet(experience?.name);
      setWidgetArray(experience?.widgets);
      setLayouts(experience?.grid_layout);
    }
  }

  const renderCanvas = () => {
    if (experience?.assets === null || experience?.assets?.length < 5) {
      return null;
    } else {
      return (
        <Canvas
          key={experience.url}
          id={experience.url}
          experienceUrl={experience?.url}
          isEditable={isEditable}
          isOwner={isOwner}
          widgetArray={widgetArray}
          setWidgetArray={setWidgetArray}
          layouts={layouts}
          setLayouts={setLayouts}
          assets={JSON.stringify(experience?.assets)}
      />
      )
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={8} md={8}>
          <AssetBar assets={experience?.assets} />
          <p>{experience.description}</p>
        </Grid>
        <Grid item xs={4} md={4} direction={"column-reverse"}>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              pr: 2,
            }}
          >
          { isOwner && <WidgetSettingsButton experienceUrl={experience?.url} layouts={layouts} isEditable={isEditable} setIsEditable={setIsEditable} widgetArray={widgetArray} setWidgetArray={setWidgetArray} showAddSources={showAddSources} setShowAddSources={setShowAddSources} />}
          </Box>
        </Grid>
      </Grid>
      <div>
        {((experience?.assets?.length < 5 ?? true) || showAddSources) && <AddAssetBox experience={experience} setShowAddSources={setShowAddSources} />}
        {renderCanvas()}
      </div>
    </div>
);
}

export default Experience;

