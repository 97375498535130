import { LoadProfile, LoadFollowing, LoadPinned, Pin, Follow, 
    AddExperience, AddExperienceAssets, RemoveExperienceAssets, 
    UpdateExperience, GetUserMedia, AddUserMedia, RemoveUserMedia} from '../../services/ProfileService';
import * as ActionTypes from '../ActionTypes';

export const LoadProfileAction = () => {
    return (dispatch) => {
        dispatch({type: ActionTypes.LOADING});
        LoadProfile().then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.LOAD_PROFILE_SUCCESS,res});
                //BHTODO: Finish calling load following action
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_PROFILE_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const LoadPinnedAction = () => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        LoadPinned().then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.LOAD_PINNED_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const PinAction = (assetType, assetId) => {
    return (dispatch, action) => {
        //dispatch({type: ActionTypes.LOADING});
        Pin(assetType, assetId).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.PIN_SUCCESS, res});
                dispatch(LoadPinnedAction());
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const AddExperienceAction = (name, description, layout) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        AddExperience(name, description, layout).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.ADD_EXPERIENCE_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const UpdateExperienceAction = (experienceUrl, layout, widgets, settings) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        UpdateExperience(experienceUrl, layout, widgets, settings).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.UDPATE_EXPERIENCE_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const AddExperienceAssetsAction = (url, assets) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        AddExperienceAssets(url, assets).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.ADD_EXPERIENCE_ASSETS_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const RemoveExperienceAssetsAction = (url, assets) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        RemoveExperienceAssets(url, assets).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.REMOVE_EXPERIENCE_ASSETS_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const LoadFollowingAction = () => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        LoadFollowing().then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.LOAD_FOLLOWING_SUCCESS,res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const FollowingAction = (assetType, assetId) => {
    return (dispatch, action) => {
        //dispatch({type: ActionTypes.LOADING});
        Follow(assetType, assetId).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.FOLLOW_SUCCESS, res});
                dispatch(LoadFollowingAction());
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const LoadUserMediaAction = (assetType, assetIdArray) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        GetUserMedia(assetType, assetIdArray).then((res) => {
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.LOAD_USER_MEDIA_SUCCESS, res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const AddUserMediaAction = (assetType, assetId, userMediaJson) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        AddUserMedia(assetType, assetId, userMediaJson).then((res) => {  
            dispatch({type: ActionTypes.ADD_USER_MEDIA_SUCCESS, res});
            dispatch(LoadUserMediaAction(assetType, assetId));
        }, error => { 
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}

export const RemoveUserMediaAction = (assetType, assetId, userMediaJson) => {
    return (dispatch) => {
        //dispatch({type: ActionTypes.LOADING});
        RemoveUserMedia(assetType, assetId, userMediaJson).then((res) => {  
            dispatch({type: ActionTypes.REMOVE_USER_MEDIA_SUCCESS, res});
            dispatch(LoadUserMediaAction(assetType, assetId));
        }, error => { 
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}