import React, { useState, useEffect } from "react";
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import '../../node_modules/react-grid-layout/css/styles.css';
import '../../node_modules/react-resizable/css/styles.css';
import css from '../style/components.module.scss';

import Widget from '../components/widgets/Widget';
import Profile from '../components/widgets/Profile';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

//Images & Icons
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import CakeIcon from '@mui/icons-material/Cake';
import ChurchIcon from '@mui/icons-material/Church';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useStyles, } from '../Style';

import PersonService from "../api/PersonService";
import * as ActionTypes from '../redux/ActionTypes';
import { format } from "date-fns";

export default function Person() {
  const classes = useStyles();
  const theme = useTheme();
  let { url } = useParams();

  const { data: personData, isLoading, isSuccess } = useQuery(
    ["person-" + url],
    () => PersonService.getPerson(url),
    {
      staleTime: 1 * 60 * 1000, 
      cacheTime: 5 * 60 * 1000  
    }
  );

  const person = isSuccess ? personData.data : {};
  const personPlaylist = isSuccess ? personData[0].playlist_data : {};
  const personFeed = isSuccess ? personData[0].feed_data : {};
  const personCalendar = isSuccess ? personData[0].calendar_data : {};

  const dispatch = useDispatch();
  const [ pageSet, setPageSet ] = useState(false);
  const asset = JSON.stringify([{"type" : "Person", "id": person.id}]);

  useEffect(() => {
    dispatch({type: ActionTypes.SET_PAGE_INFO, pageName: person?.name, assetType: "Person", assetId: person?.id});
    return () => {};
  }, [])

    const renderPhoto = () => {
      if (person?.photo?.url) {
        return (
            <Avatar style={{ width: '235px', height: '235px', margin: '1em auto', marginTop: '0' }} alt={person.name} src={'https://cms.starity.com/images/image?url='+person.photo.url} />
        );  
      }
    }

    if (!isSuccess) {
      return "Loading...";
    } else {
      if (pageSet !== person?.name) {
        dispatch({type: ActionTypes.SET_PAGE_INFO, pageName: person?.name, assetType: "Person", assetId: person?.id});
        setPageSet(person?.name);
      }
    }

    if (person.birth_date)  { 
      var birthdate = new Date(person.birth_date.split("T")[0]);
    }
    if (person.death_date)  { 
      var deathdate = new Date(person.death_date.split("T")[0]);
    }

  return (
    <div className={css.page}>
      <div className={classes.subTitle}>
        {isSuccess && person.birth_date && <Chip size="medium" label={format(birthdate, "MMMM do, yyyy")} avatar={<Avatar><CakeIcon /></Avatar>} />  }
        {isSuccess && person.death_date && <span> &nbsp; <Chip size="medium" label={format(deathdate, "MMMM do, yyyy")} avatar={<Avatar><ChurchIcon /></Avatar>} /></span> }
        {isSuccess && person.birth_place && <span> &nbsp; <Chip size="medium" label={person.birth_place} avatar={<Avatar><LocationOnIcon /></Avatar>} /></span> }
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Widget widgetName="Playlist" type="playlist" width={4} height={6} assets={asset} widget={{"i": "personPlaylist", "type": "playlist"}} id={'personPlaylist'} />
            <Widget widgetName="Calendar" type="calendar" width={4} height={6} assets={asset} widget={{"i": "personCal", "type": "calendar"}} id={'personCal'} />
          </Grid>

          <Grid item xs={12} md={4} lg={4} style={{height: '65em'}}>
            <Widget widgetName="Feed" type="feed" width={4} height={6} assets={asset} widget={{"i": "personFeed", "type": "feed"}} id={'personFeed'} />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>

            {renderPhoto()}

          </Grid>
        </Grid>
      </div>
    </div>
  );
}