import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { Box, Grid } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles, } from '../Style';
import * as ActionTypes from '../redux/ActionTypes';


function Blog() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();


  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <Box>
    <Grid container className={css.home} justifyContent="center">
      <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
        <h4>Starity Blog</h4>
        <h1><span className={css.star}>★</span> Blog.</h1>
        <p>
          Coming soon...
        </p>
      </Grid>
      <Grid item xs={12} md={5} justifyContent="flex-end" alignItems="flex-start" order={{ xs: 1, md: 1 }}>
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              pr: 2,
            }}
          >
            <img src="/images/stop.gif" alt="Hold Up" className={css.pageGif} />
          </Box>
      </Grid>
    </Grid>
    </Box>
  );
}

export default Blog;