import * as ActionTypes from '../ActionTypes';

const initState = {
    userLoggedIn: false,
    userProfile: "",
    userFollowing: [],
    userPinned: [],
    userExperiences: [],
    userMedia: [],
};

const ProfileReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.LOADING:
            return {
                ...state,
                userProfile: "loading...",
            };
        case ActionTypes.LOAD_PROFILE_SUCCESS:
            return {
                ...state,
                userLoggedIn: true,
                userExperiences: action.res.experiences,
                userFollowing: action.res.following,
                userProfile: action.res,
            };
        case ActionTypes.LOAD_PROFILE_ERROR:
            return {
                ...state,
                userExperiences: action.res.experiences,
                userFollowing: action.res.following,
                userProfile: action.res,
        };

        case ActionTypes.LOAD_FOLLOWING_SUCCESS:
            return {
                ...state,
                userFollowing: action.res,
        };
        case ActionTypes.LOAD_FOLLOWING_ERROR:
            return {
                ...state,
                userFollowing: action.res,
        };

        case ActionTypes.LOAD_PINNED_SUCCESS:
            //console.log("LOAD_PINNED_SUCCESS ");
            return {
                ...state,
                userPinned: action.res,
        };
        case ActionTypes.LOAD_PINNED_ERROR:
            return {
                ...state,
                userPinned: action.res,
        };

        case ActionTypes.LOAD_EXPERIENCES_SUCCESS:
            return {
                ...state,
                userExperiences: action.res,
        };

        case ActionTypes.LOAD_USER_MEDIA_SUCCESS:
            return {
                ...state,
                userMedia: action.res.data,
        };


        case ActionTypes.LOGOUT_SUCCESS:
            //console.log("LOGOUT_SUCCESS ");
            return {
                ...state,
                userLoggedIn: false,
                userProfile: "",
                userExperiences: [],
                userMedia: [],
                userPinned: [],
                userFollowing: [],
        };
        case ActionTypes.CODE_ERROR:
        return {
                ...state,
                userProfile:
                "There seems to be a problem, please refresh your browser",
        };

    default:
    return state;
    }
};
export default ProfileReducer;