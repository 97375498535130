import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, MobileStepper, Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SwipeableViews from "react-swipeable-views";
import css from '../style/components.module.scss';


interface IScrollerProps {
  children: JSX.Element[];
  itemsPerView: number;
}

export const Scroller: React.FC<IScrollerProps> = ({
  children,
  itemsPerView,
}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = Math.ceil(children.length / itemsPerView);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {[...Array(maxSteps)].map((_, index) => {
          return (
            <Box
              key={`step-${index}`}
              sx={{
                display: "flex",
                alignItems: "stretch",
              }}
            >
              {children
                .slice(
                  index * itemsPerView,
                  index * itemsPerView + (itemsPerView + 1)
                )
                .map((child, childIndex) => {
                  return (
                    <Box sx={{ mx: 1 }} key={`step-${index}-${childIndex}`}>
                      {React.cloneElement(child as JSX.Element, {})}
                    </Box>
                  );
                })}
            </Box>
          );
        })}
      </SwipeableViews>
      { (maxSteps > 1) &&
      <MobileStepper
        style={{ backgroundColor: "transparent", padding: '1em', bottom: '1em'}}
        classes={{
          root: css.dotRoot,
          dot: css.dot,
          dotActive: css.dotActive,
        }}
        steps={maxSteps}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className={css.scrollerButton}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={css.scrollerButton}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    }
    </div>
  );
};