import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import css from '../style/components.module.scss';

import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../Style';
import { ButtonGroup, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as ActionTypes from '../redux/ActionTypes';
import ExperienceBox from '../components/ExperienceBox';

function Home() {
  const classes = useStyles(); 
  const theme = useTheme();
  const dispatcher = useDispatch();
  const history = useHistory();

  useEffect(() => {
    //if logged in, redirect to user profile
    if (localStorage.getItem('user-token') !== null) {
      history.push('/user');
    }

    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "TV ★ Sports ★ Music ★ Movies ★ Internet", assetType: 'home', assetId: null});
  }, []);

  return (
    <>
    <div className={css.home}>

      <Grid container spacing={3} className={classes.experiences} >
        <Grid item md={7}>
          <br />
          <h4 style={{"fontSize": ".9em"}}>TV <span style={{"color": "#365a92"}}>★</span> Movies <span style={{"color": "#365a92"}}>★</span> Sports <span style={{"color": "#365a92"}}>★</span> Music <span style={{"color": "#365a92"}}>★</span> Internet</h4>
          <h1>Personalized<br /> Entertainment Experiences.</h1>
          <h3>Discover and create amazing entertainment dashboards with exactly what you want, and nothing you don't.</h3>
          <br />
          <Grid container>
              <Button component={Link} to="/user/register" className={css.buttonAlt} style={{"marginRight": ".5em"}}>Join The Beta</Button>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <img src="images/hero.png" alt="" className="" style={{"width": "100%", "marginTop": "2em"}} />
        </Grid>
      </Grid>

    </div>
    <div className={css.homeFeature}>
      <div className={css.wrapper}>

        <h4 className={css.header}>Dynamic Blocks</h4>
        <h1 style={{"fontSize": "1.5em", "marginBottom": "1em"}}>The New Way to Keep Up Your Favorites</h1>

        <Grid container spacing={2} className={classes.experiences} >
          <Grid item md={8}>
            <div className={css.homeFeatureBox}>
              <img src="images/playlist.png" alt="" className={[css.image, css.left].join(' ')} style={{"width": "80%", "marginTop": "0", "display": "block"}} />
              <h3>Playlist</h3>
              <p>Find where to stream your favorite content and keep track of what you watch and listen to. Playlists automatically populate with new episodes, albums and more.</p>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={css.homeFeatureBox}>
              <img src="images/scoreboard.png" alt="" className={css.image} style={{"width": "68%", "display": "block", "margin": "auto"}} />
              <h3>Scoreboard</h3>
              <p>Track current, upcoming and past scores from your favorite teams, athletes and leagues.</p>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className={css.homeFeatureBox}>
              <img src="images/calendar.png" alt="" className={[css.image, css.left].join(' ')} style={{"width": "68%", "display": "block"}} />
              <h3>Calendar</h3>
              <p>Always be in-the-know on new episodes, albums, concerts, events and more.</p>
            </div>
          </Grid>
          <Grid item md={8}>
            <div className={css.homeFeatureBox}>
              <Grid container spacing={2} className={classes.experiences} >
                <Grid item md={7}>
                  <h3 className={css.altBox}>Feed</h3>
                  <p>Stay updated with news, social posts, appearances, reviews and more in one integrated feed with multiple filtering options.</p>
                </Grid>
                <Grid item md={5}>
                  <img src="images/feed.png" alt="" className="" style={{"width": "100%"}} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>


    <div className={css.home}>
      <h4>Featured Examples</h4>

      <br />
      <Grid container spacing={3} className={classes.experiences} >
          <Grid item md={4}>
            <ExperienceBox title={"100% True Crime"}  url={"/x/100-true-crime-6468690e74217"} description={"The best curated true crime shows broken into multiple playlists."} />
          </Grid>
          <Grid item md={4}>
            <ExperienceBox title={"Most Popular HipHop"}  url={"/x/hiphop-645447351df81"} description={"Inspired by the top Rap and HipHop Spotify playlist artists."} />
          </Grid>
          <Grid item md={4}>
            <ExperienceBox title={"Flashback: ~2000"} url={"/x/flashback-2000-6458ba9ace14e"} description={"Catch up with your favorite stars and shows of 2000."} />
          </Grid>

          <Grid item md={4}>
            <ExperienceBox title={"USA Soccer"}  url={"/x/usa-soccer-64a3dd69c333d"} description={"All of the best players representing the United States across the globe."} />
          </Grid>
          <Grid item md={4}>
            <ExperienceBox title={"XXL Freshman Class 2023"}  url={"/x/xxl-freshman-class-649a9ac654bd6"} description={"Follow all 12 featured artists from this years XXL freshman lineup."} />
          </Grid>
          <Grid item md={4}>
            <ExperienceBox title={"NFL - Top 2024 Draft Prospects"} url={"/x/2024-nfl-draft-prospects-64a3ddc416861"} description={"Track over 50 of the top NFL prospects through their final year."} />
          </Grid>
      </Grid>

      <br /><br />
    </div>
    </>
  );
}

export default Home;

/*
      <br /><br />
      <hr />
      <br /><br />

      <h4>Don't Fear Reading The Comment Section Again</h4>
      <h1>Find Your Club Crew.</h1>
      <h3>Limit comments and reactions from only your subscribed clubs of similar interests. Troll &amp; bot free.</h3>

      <br /><br />
      <br /><br />
*/