import React,{useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector} from 'react-redux';
import * as _ from 'underscore';

import css from './style/components.module.scss';
import Nav from './components/Nav';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';

import { SearchAction } from './redux/actions/AppActions';
import { LoadFollowingAction, LoadPinnedAction, LoadProfileAction, LoadUserMediaAction } from './redux/actions/ProfileActions';
import { BrowserRouter as Router, Link } from "react-router-dom";

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { styled, ThemeProvider } from '@material-ui/core/styles';

//Images & Icons
import Logo from './images/logos/logo_.png';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';

//MUI Components 
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { TextField } from "@material-ui/core";

import { useStyles } from './Style';
import Routes from "./Routes";
import ScrollToTop from './ScrollToTop';
import { Pin } from '@mui/icons-material';
import mixpanel from 'mixpanel-browser';


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function App(props) {

  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();
  const { height, width } = useWindowDimensions();
  let isMobile = width < 800;
  
  const [open, setOpen] = React.useState(!isMobile ?? true);
  const [inputValue, setInputValue] = React.useState("");
  const [searchOpen, setSearchOpen] = React.useState(false);
  
  const pageDetails = useSelector(state=>state.pageDetails);
  const userFollowing = useSelector(state=>state?.userDetails?.userFollowing ?? []);
  const profileResponse = useSelector(state=>state.userDetails.userProfile);
  const userPinned = useSelector(state=>state?.userDetails?.userPinned);
  const userExperiences = useSelector(state=>state.userDetails.userExperiences);
  const searchResults = useSelector(state=>state?.appDetails?.searchResults ?? {});

  document.title = "Starity: " + pageDetails?.pageName ?? "";

  useEffect(() => {
    mixpanel.init('433d6cd71e85b31f4517ace4e13231b6', { debug: true, track_pageview: true, persistence: 'localStorage' });

    const token = localStorage.getItem('user-token');
    if (token !== undefined && token !== null && token !== "") {
      dispatcher(LoadProfileAction());
      dispatcher(LoadPinnedAction());
      dispatcher(LoadFollowingAction());
      dispatcher(LoadUserMediaAction());
      mixpanel.identify(profileResponse.email)
    }
    return () => {};
  }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  var searchValues = () => { 
    var result = [];
    if(searchResults.length > 0) {
      searchResults.forEach(item => {
        result.push({
          label: item.name,
          url: item.url,
          type: item.type,
        });
      });
    }
    return result;
  }

  var isStarred = () => {
    var result = false;
    //Check userFollowing for current page asset type and id
    if (userFollowing?.length > 0) {
      userFollowing.forEach(following => {
        if (following.type === pageDetails.assetType && following.id === pageDetails.assetId) {
          result = true;
        }
      });
    }
    return result;
  }

  var isPinned = () => {
    var result = false;
    //Check userPinned for current page asset type and id
    if (userPinned.length > 0) {
      userPinned.forEach(pin => {
        if (pin.asset_type == pageDetails.assetType && pin.asset_id == pageDetails.assetId) {
          result = true;
        }
      }
      )
    }
    return result;
  }

  const handleInputChange = (value) => {
    dispatcher(SearchAction(value));
  }
  const throttleInputChange = useRef(_.throttle(handleInputChange, 1000)).current;
  
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <ScrollToTop />
      <div className={classes.root}>
        <CssBaseline />
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <DrawerHeader className={classes.toolbar}>
              <Link to={'/'} ><img src={Logo} alt="Logo" width="170" className={css.Logo} /></Link>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} className={classes.menuButton} style={{width: open ? '' : '100%'}} >
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <Nav userExperiences={userExperiences} userPinned={userPinned} setOpen={setOpen} isMobile={isMobile} />
          </Drawer>
          <main className={classes.content}>

            <AppBar
            position="absolute"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <Grid container spacing={0} style={{"maxWidth": "100em"}}>
                <Grid item xs={9}>
                  <PageHeader pageName="" isStarred={isStarred(pageDetails?.assetId)} isPinned={isPinned(pageDetails?.assetId)} />
                </Grid>
                <Grid item xs={3}>
                  
                <Autocomplete
                    open={searchOpen}
                    onOpen={() => {
                      // only open when in focus and inputValue is not empty
                      if (inputValue) {
                        setSearchOpen(true);
                      }
                    }}
                    onClose={() => setSearchOpen(false)}
                    inputValue={inputValue}
                    onInputChange={(e, value, reason) => {
                      setInputValue(value);
                      // only open when inputValue is not empty after the user typed something
                      if (!value) {
                        setSearchOpen(false);
                      } else {
                        throttleInputChange(value);
                      }
                    }}
                    //onChange={(event, value) => dispatcher(SearchLinkAction(value.url))}
                    id="combo-box-demo"
                    style={{"color": "#365a92"}}
                    options={searchValues()}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li>
                        <Box component={Link} to={'/'+option.type.toLowerCase()+'/'+option.url} {...props}>
                            {option.label}
                        </Box>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Search" variant="standard"
                      //InputProps={{
                        //startAdornment: (
                          //<InputAdornment position="start">
                            //<SearchIcon />
                          //</InputAdornment>
                        //),
                      //}}
                      />
                      //<Input
                        //{...params}
                        //id="input-with-icon-adornment"
                        //startAdornment={
                          //<InputAdornment position="start">
                            //<SearchIcon />
                          //</InputAdornment>
                        //}
                      ///>
                    )}
                  />
                </Grid>
              </Grid>
            </Toolbar>
            </AppBar>
            <DrawerHeader />
            <Routes/>
          </main>
      </div>
      <PageFooter open={open} />
    </Router>
    </ThemeProvider>
  );
}

export default App;