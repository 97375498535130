import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

function ReadButton({links}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        //if only one link, open it
        if (links.length === 1) {
            //open the url
            window.open(links[0].url, '_blank');
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
        <Button
            id="fade-button"
            aria-controls="fade-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={<ReadMoreIcon />}
            style={{"color": "#f50057"}}
        >
            READ
        </Button>
        <Menu
            id="fade-menu"
            MenuListProps={{
            'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
            <MenuItem onClick={handleClose}>TMZ</MenuItem>
            <MenuItem onClick={handleClose}>BuzzFeed</MenuItem>
            <MenuItem onClick={handleClose}>MSNBC</MenuItem>
        </Menu>
        </div>
    );
}

export default ReadButton;
