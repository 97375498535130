import { Search } from '../../services/AppService';
import * as ActionTypes from '../ActionTypes';

export const SearchAction = (search) => {
    return (dispatch, action) => {
        dispatch({type: ActionTypes.SEARCH_LOADING});
        Search(search).then((res) => {
            //console.log(res);
            //if(res.hasOwnProperty('name') && res.success === true){
                dispatch({type: ActionTypes.SEARCH_SUCCESS, res});
            //}else if(res.hasOwnProperty('success') && res.success === false){
                //dispatch({type: ActionTypes.LOAD_FOLLOWING_ERROR,res});
            //}
        }, error => {
            dispatch({type : ActionTypes.CODE_ERROR, error})
        })
    }
}