import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

import { useStyles, } from '../Style';

import { Circle } from '@mui/icons-material';
import * as ActionTypes from '../redux/ActionTypes';


function Status() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "System Status", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h4>Mirrored From http://status.starity.com</h4>
      <h1>System Status.</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <h2>Primary Features</h2>
          <p>
            <Circle style={{"color":"#adad2e", "fontSize":".75em"}} /> Login
          </p>
          <p>
            <Circle style={{"color":"#adad2e", "fontSize":".75em"}} /> Experiences
          </p>
          <p>
            <Circle style={{"color":"#adad2e", "fontSize":".75em"}} /> Widgets
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <h2>Apple iOS</h2>
          <p>
            <Circle style={{"color":"#adad2e", "fontSize":".75em"}} /> v0.1<br />
            <small>0 Minor | 0 Major</small><br />
          </p>
          <p>
            <Circle style={{"color":"#b38587", "fontSize":".75em"}} /> v0.1<br />
            <small>4 Minor | 1 Major</small><br />
          </p>
          <p>
            <Circle style={{"color":"red", "fontSize":".75em"}} /> &lt; v0.1<br />
            <small>Not Supported</small><br />
          </p>
        </Grid>
        <Grid item xs={12} md={4}>
          <h2>Google Android</h2>
          <p>
            <Circle style={{"color":"#adad2e", "fontSize":".75em"}} /> v0.1<br />
            <small>0 Minor | 0 Major</small><br />
          </p>
          <p>
            <Circle style={{"color":"#b38587", "fontSize":".75em"}} /> v0.1<br />
            <small>4 Minor | 0 Major</small><br />
          </p>
          <p>
            <Circle style={{"color":"red", "fontSize":".75em"}} /> &lt; v0.1<br />
            <small>Not Supported</small><br />
          </p>
        </Grid>
      </Grid>
      <br />
    </div>
  );
}

export default Status;