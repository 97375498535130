import HttpService from './HttpService';
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cms.starity.com";

export const RegisterUserService = (credentials) => {
    const http = new HttpService();
    let signupUrl = "users/register";
    try {
        const data = http.postData(credentials, signupUrl);
        return data;
    } catch (error) {
        return error;
    }
}

export const LoginUserService = async (credentials) => {
    const http = new HttpService();
    let loginUrl = "users/login";
    try {
        const data = await http.postData(credentials, loginUrl);
        return data;
    } catch (error) {
        return error;
    }
}

export const LogOutUserService = async () => {
    const http = new HttpService();
    let loginUrl = "users/logout";
    const tokenId = "user-token";
    try {
        const data = await http.getData(loginUrl, tokenId);
        return data;
    } catch (error) {
        return error;
    }
}