import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { Box, Grid } from "@mui/material";
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles, } from '../Style';
import * as ActionTypes from '../redux/ActionTypes';


function Api() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();


  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <Box>
    <Grid container className={css.home}   justifyContent="center">
      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
        <h4>Coming Soon</h4>
        <h1><span style={{"color":"#f50057"}}>★</span>API+ Access.</h1>
        <p>
          * Starity Real-Time Sports Data API.<br />
          * Starity People Data API.<br />
          * Starity Show, Movie, Podcast API.<br /><br />
        </p>
        <p>
          <Button variant="outlined" size="large"><strong>Join Waitlist</strong></Button>
        </p>
        <br />
        <br />
      </Grid>
      <Grid item xs={12} md={6} justifyContent="center" alignItems="flex-start" order={{ xs: 1, md: 1 }}>
        <img src="/images/api.gif" alt="API" className={css.pageGif} />
      </Grid>
    </Grid>
    </Box>
  );
}

export default Api;