import React from 'react';

import css from '../../../style/components.module.scss';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    margin: 0,
  },
  h2: {
    margin: 0,
    padding: 0,
  },
});

function FeedItem(props) {

  const classes = useStyles();

  const renderAssetAvatars = () => {
    return props.assets.map((asset, index) => (
        <Avatar key={asset.url} component={Link} to={'/' + asset.url} alt={asset.name} src={asset.photo?.url} sx={{ width: 24, height: 24 }} style={{textDecoration: 'none'}}  />
    ));
  };

  const renderCardMedia = () => {
    let imageHeight = "225";
    let imageMaxHeight = "32em";
    let imagePosition = "relative";

    if (props.image) {
      if (props.type === "social") {
        imageHeight = "500";
        imageMaxHeight = "25em";
        imagePosition = "absolute";
      }

      return (
        <CardMedia
          component="img"
          alt=""
          height={imageHeight}
          image={props.image}
          title=""
          style={{"position": imagePosition, "objectPosition": "top", "maxHeight": imageMaxHeight}}
         />
      );
    }
  };



  return (
    <Card className={css.FeedItem} style={{minHeight: '32em', margin: '.5em'}}>

        {renderCardMedia()}
        <CardContent style={{"paddingBottom": "0"}}>
            <h2 className={classes.h2}>
              <a href={props.url} target='_blank'>{props.title}</a>
            </h2>
          <Typography variant="body2" color="textSecondary" component="p">
            TMZ.com
          </Typography>
        </CardContent>

      <CardActions>
        <AvatarGroup size="small" style={{"marginLeft":"auto"}}>
          {renderAssetAvatars()}
        </AvatarGroup>
      </CardActions>
    </Card>
  );
}

export default FeedItem;