import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useStyles, } from '../Style';
import * as ActionTypes from '../redux/ActionTypes';


function Terms() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h4>Keep Calm and Agree</h4>
      <h1><span className={css.star}>★</span> Terms of Service.</h1>
      <p>
        ...<br />
      </p>
      <br />
      <br />
    </div>
  );
}

export default Terms;