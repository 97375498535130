export const RESTART_AUTH_RESPONSE = 'RESTART_AUTH_RESPONSE';
export const LOADING = 'LOADING';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const CODE_ERROR = 'CODE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_PROFILE_ERROR = 'LOAD_PROFILE_ERROR';
export const LOAD_PINNED_SUCCESS = 'LOAD_PINNED_SUCCESS';
export const LOAD_PINNED_ERROR = 'LOAD_PINNED_ERROR';
export const LOAD_FOLLOWING_SUCCESS = 'LOAD_FOLLOWING_SUCCESS';
export const LOAD_FOLLOWING_ERROR = 'LOAD_FOLLOWING_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';

export const FOLLOW_TOGGLE = 'FOLLOW_TOGGLE';
export const FOLLOW_SUCCESS = 'FOLLOW_SUCCESS';

export const PIN_TOGGLE = 'PIN_TOGGLE';
export const PIN_SUCCESS = 'PIN_SUCCESS';

export const SEARCH_LOADING = 'SEARCH_LOADING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';

export const SET_PAGE_INFO = 'SET_PAGE_INFO';

export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const ADD_EXPERIENCE_SUCCESS = 'ADD_EXPERIENCE_SUCCESS';
export const ADD_EXPERIENCE_ERROR = 'ADD_EXPERIENCE_ERROR';
export const ADD_EXPERIENCE_ASSETS_SUCCESS = 'ADD_EXPERIENCE_ASSETS_SUCCESS';
export const ADD_EXPERIENCE_ASSETS_ERROR = 'ADD_EXPERIENCE_ASSETS_ERROR';
export const LOAD_EXPERIENCES_SUCCESS = 'LOAD_EXPERIENCES_SUCCESS';
export const REMOVE_EXPERIENCE_SUCCESS = 'REMOVE_EXPERIENCE_SUCCESS';

export const REMOVE_EXPERIENCE_ASSETS_SUCCESS = 'REMOVE_EXPERIENCE_ASSETS_SUCCESS';
export const REMOVE_EXPERIENCE_ASSETS_ERROR = 'REMOVE_EXPERIENCE_ASSETS_ERROR';
export const UDPATE_EXPERIENCE_SUCCESS = 'UDPATE_EXPERIENCE_SUCCESS';

export const OPEN_ADD_ASSET_MODAL = 'OPEN_ADD_ASSET_MODAL';

export const ADD_USER_MEDIA = 'ADD_USER_MEDIA';
export const ADD_USER_MEDIA_SUCCESS = 'ADD_USER_MEDIA_SUCCESS';
export const LOAD_USER_MEDIA_SUCCESS = 'LOAD_USER_MEDIA_SUCCESS';
export const LOAD_USER_MEDIA_ERROR = 'LOAD_USER_MEDIA_ERROR';
export const REMOVE_USER_MEDIA = 'REMOVE_USER_MEDIA';
export const REMOVE_USER_MEDIA_SUCCESS = 'REMOVE_USER_MEDIA_SUCCESS';
