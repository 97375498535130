import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import css from '../style/components.module.scss';

//Layout & Style
import { useTheme } from '@material-ui/core/styles';
import { useStyles, } from '../Style';

import * as ActionTypes from '../redux/ActionTypes';


function Reactions() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatcher = useDispatch();

  useEffect(() => {
    dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Reactions", assetType: null, assetId: null});
  }, []);

  return (
    <div className={css.home}>
      <h4>Coming Soon...</h4>
      <h1>Join Your Club.</h1>
      <p>Reactions are a modern take on the old comment system. Join clubs of like minded users and use and view club specific reactions across Starity.</p>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Reactions;