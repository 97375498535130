import React, { useState, useRef, useLayoutEffect, useReducer, useEffect, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import FeedItem from './feed/FeedItem';
import FeedItemSmall from './feed/FeedItemSmall';
import SocialItem from './feed/SocialItem';
import { GridScroller } from '../GridScroller.tsx';

function Feed({feedData, dimensions, height, width, id, ...props}) {

  function getItemsPerView() {
    let itemsX, itemsY;

    //display 1 or 2 per row
    const $rowCount = (width > 4 && dimensions.width > 680) ? 1 : 2;

    //handle mobile
    if (dimensions.width <= 680) {
     return Math.floor(height - 1);
    }

    if (width == 0) {
      itemsX = 1;
    } else {
      itemsX = Math.floor(width / 4);
    }

    if (height == 0) {
      itemsY = 1;
    } else {
      itemsY = Math.floor(height / 3);
    }

    if (itemsX > 0 && itemsY > 0) {
      return itemsX * itemsY / $rowCount;
    } else {
      return 1;
    }



  }

  var items = Object.keys(feedData).map((feedItem) => {
      return (
      <Grid item xs={(width > 4 && dimensions.width > 680) ? 6 : 12} key={feedData[feedItem].url}>
        {(dimensions.width <= 680) ?
          <FeedItemSmall key={feedData[feedItem].title} type={feedData[feedItem].type} title={feedData[feedItem].title} image={'https://cms.starity.com/images/image?url='+feedData[feedItem].image.url} description={feedData[feedItem].description} summary={feedData[feedItem].summary} url={feedData[feedItem].url} item={feedData[feedItem]} assets={feedData[feedItem].assets} />
        : (feedData[feedItem].type == "social"
          ? <SocialItem key={feedData[feedItem].title} title={feedData[feedItem].title} image={'https://cms.starity.com/images/image?url='+feedData[feedItem].image.url} summary={feedData[feedItem].summary} description={feedData[feedItem].description} url={feedData[feedItem].url} item={feedData[feedItem]} assets={feedData[feedItem].assets}  />
          : <FeedItem key={feedData[feedItem].title} title={feedData[feedItem].title} image={'https://cms.starity.com/images/image?url='+feedData[feedItem].image.url} summary={feedData[feedItem].summary} url={feedData[feedItem].url} item={feedData[feedItem]} assets={feedData[feedItem].assets} />
        )}
      </Grid>
      );
  });

  return (
    <div className="Feed">
      <GridScroller itemsPerView={getItemsPerView()}>
        {items}
      </GridScroller>
    </div>
  );
}

export default Feed;
