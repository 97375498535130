import React,{useEffect, useCallback} from 'react';
import {Card} from '@material-ui/core';
import {useStyles} from '../Style';
import {LogoutAction} from '../redux/actions/AuthActions';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory,Link} from 'react-router-dom';
import { Button } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import * as ActionTypes from '../redux/ActionTypes';


export default function Profile() {
    
    const classes = useStyles();
    const dispatcher = useDispatch();
    const history = useHistory();
    const profileResponse = useSelector(state=>state.userDetails.userProfile);
    const followingResponse = useSelector(state=>state.userDetails.userFollowing);
    
    useEffect(() => {
        dispatcher({type: ActionTypes.SET_PAGE_INFO, pageName: "Profile", assetType: null, assetId: null});
        return () => {};
    }, [])

    // Logs the user out
    const logOut = () => {
        dispatcher(LogoutAction());
        history.push("/user/login");
    }

    return (
        <div className={classes.fullWidthProfile}>
            <Grid container spacing={3} >
                <Grid item xs={12} md={8}>
                    <Card className={classes.card}>
                        <h3>Subscribed Streaming Services</h3>
                        <Grid container >
                            <Grid item xs={6} md={4}>
                                <input name="x" type="checkbox" /> Amazon Prime
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x" type="checkbox" /> Apple TV+
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x03" type="checkbox" /> Discovery+
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x2" type="checkbox" /> Disney+
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x2" type="checkbox" /> ESPN+
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="hbomax" type="checkbox" /> HBO Max
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x3" type="checkbox" /> Hulu
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="netflix" type="checkbox" /> Netflix
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x3" type="checkbox" /> Paramount+
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="peacock" type="checkbox" /> Peacock
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <input name="x3" type="checkbox" /> Showtime
                            </Grid>
                        </Grid>
                        <br /><br />
                        <h3>Service Integrations</h3>
                        <Grid container>
                            <Grid item xs={6} md={4}>
                                <strong>Spotify API</strong><br />
                                <Button component={Link} to="/user/login" variant="contained" size="small">Connect</Button>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                Play music and save to your Spotify playlist from Starity.
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                
                <Grid item xs={12} md={4}>
                    <Button onClick={logOut}>Logout</Button>
                    <Card  className={classes.card}>
                    {
                        profileResponse !== "" && profileResponse !== null && profileResponse.name != null ?
                        <div>
                        <h3>Name:</h3> {profileResponse.name}
                        <h3>Email:</h3> {profileResponse.email}
                        <h3>Member Since:</h3> {profileResponse.created_at}
                        </div>
                        :
                        <div>...</div>
                    }
                    </Card>

                    <Card  className={classes.card}>
                    {
                        followingResponse !== "" && followingResponse !== null ?
                        followingResponse.map(following => {
                            return (
                                <div>
                                    <strong>{following.asset_type}</strong> {following.asset_id}
                                </div>
                            );
                        })
                        :
                        <div>...</div>
                    }
                    </Card>

                </Grid>
            </Grid>
        </div>
    );
}