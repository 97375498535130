import React from 'react';
import { Link } from 'react-router-dom';
import { PhotoPlaceholder } from 'react-placeholder-image';
import ProfileItem from './ProfileItem';
import Pagination from '@material-ui/lab/Pagination';
import { GridScroller } from '../GridScroller.tsx';
import css from '../../style/components.module.scss';

function List({calendarData, id, width, height, ...props}) {

  const itemsPerView = () => {
    if (height == 2) { return 3; }
    if (height == 3) { return 6; }
    if (height == 4) { return 8; }
    if (height == 5) { return 12; }
    return height * 2;
  };

  var profiles = props.listData.map(function(item) {
      return <div key={item.name} className={css.ListItem}><ProfileItem name={item.name} image={item.photo?.url} description={item.description} url={item.link} /></div>;
  });

  return (
    <div className={css.List}>
      <GridScroller itemsPerView={itemsPerView()}>
        {profiles}
      </GridScroller>
    </div>
  );
}

export default List;
